import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';
import Icon from '@mui/material/Icon';
import { Box, Card } from '@mui/material';

function DefaultNavbarDropdown({
  name,
  icon,
  children,
  collapseStatus,
  light,
  href,
  route,
  collapse,
  subItems,
  navbarBackgroundColor,
  textColor,
  applyNow,
  ...rest
}) {
  const [open, setOpen] = useState(false);
  const timerRef = useRef(null);

  const contactToWhastapp = () => {
    window.open('https://wa.me/+5491163322763', '_blank');
  };

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  const linkComponent = {
    component: 'a',
    href,
    target: '_blank',
    rel: 'noreferrer',
  };

  const routeComponent = {
    component: Link,
    to: route,
  };

  const hasSubItems = Array.isArray(subItems) && subItems.length > 0;

  const handleMouseEnter = () => {
    if (hasSubItems) {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        setOpen(true);
      }, 300); // Retraso de 300 ms
    }
  };

  const handleMouseLeave = () => {
    if (hasSubItems) {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        setOpen(false);
      }, 300); // Retraso de 300 ms
    }
  };

  const openClose = () => {
    // Si el nombre es "Contact Us", abre el modal
    if (name === 'Contact Us') {
      contactToWhastapp();
    } else {
      setOpen((prev) => !prev); // De lo contrario, abre/cierra el dropdown
    }
  };

  return (
    <>
      <MKBox
        {...rest}
        mx={0.5}
        p={1}
        display="flex"
        alignItems="center"
        color={'dark'}
        opacity={light ? 1 : 1}
        sx={{
          cursor: 'pointer',
          userSelect: 'none',
          backgroundColor: 'transparent',
          position: 'relative',
        }}
        {...(route && routeComponent)}
        {...(href && linkComponent)}
        onClick={openClose} // Llama a openClose para manejar el click
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <MKTypography
          variant="menu"
          color={'menu'}
          sx={{
            p: 1,
            color: textColor,
            display: 'flex',
            alignItems: 'center',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.3)',
              borderRadius: '5px',
            },
          }}
        >
          {name}
          {hasSubItems && (
            <Icon sx={{ fontSize: 'small', pl: 0.5 }}>
              {open ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
            </Icon>
          )}
        </MKTypography>
      </MKBox>
      {name === 'Technology' && applyNow}

      {hasSubItems && (
        <Box
          sx={{
            position: 'absolute',
            top: '100%',
            left: 0,
            zIndex: 10,
            backgroundColor: navbarBackgroundColor,
            backdropFilter: 'blur(10px)',
            borderRadius: '0px 0px 25px 25px',
            minWidth: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            px: 3,
            pb: 2,
            alignItems: 'stretch',
            overflow: 'hidden',
            color: '#fff',
            opacity: open ? 1 : 0,
            transform: open ? 'translateY(0)' : 'translateY(-20px)',
            transition: 'opacity 300ms ease-in-out, transform 300ms ease-in-out',
            pointerEvents: open ? 'auto' : 'none',
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {subItems.map((item, index) => (
            <Card
              key={item.key}
              sx={{
                backgroundImage: `url(${item.image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                width: '273%',
                borderRadius: '25px',
                height: '326px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                color: '#fff',
                m: 0.5,
                boxShadow: 'none',
                p: '20px 30px',
                textAlign: 'center',
                opacity: open ? 1 : 0,
                transform: open ? 'translateY(0)' : 'translateY(-20px)',
                transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out',
                transitionDelay: `${index * 0.1}s`,
                '&:hover': {
                  transform: 'scale(1.01)',
                },
              }}
              component={Link}
              to={item.route}
            >
              <MKBox>
                <MKTypography
                  variant="h3"
                  sx={{
                    color: item.name === 'Technology' ? '#fff' : '#000',
                    fontWeight: 'bold',
                  }}
                >
                  {item.name}
                </MKTypography>
                <MKTypography
                  variant="body2"
                  sx={{ color: item.name === 'Technology' ? '#fff' : '#000' }}
                >
                  {item.description}
                </MKTypography>
              </MKBox>
            </Card>
          ))}
        </Box>
      )}
    </>
  );
}

DefaultNavbarDropdown.defaultProps = {
  children: false,
  collapseStatus: false,
  light: false,
  href: '',
  route: '',
  subItems: null,
};

DefaultNavbarDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.node,
  children: PropTypes.node,
  collapseStatus: PropTypes.bool,
  light: PropTypes.bool,
  href: PropTypes.string,
  route: PropTypes.string,
  collapse: PropTypes.bool.isRequired,
  subItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      route: PropTypes.string.isRequired,
      image: PropTypes.string,
      description: PropTypes.string,
    })
  ),
};

export default DefaultNavbarDropdown;
