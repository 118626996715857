import React from 'react';

// SwiperJS
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';

// Material UI components
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

// Custom components
import CustomContainer from '../../../DS/components/Container';
import TestimonialCard from 'DS/complex/Cards/InfoCards/TestimonialCard';
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';

function Testimonials() {
  // Datos de los testimonios
  const testimonials = [
    {
      id: 1,
      title: 'Constanza Naso',
      subtitle: 'CEO & Co Founder @ MAIA',
      quote:
        'A space to grow shoulder to shoulder with incredible people with a lot of experience and generosity, undoubtedly highly recommended.',
      direction: 'center',
      color: 'primary',
      img: 'https://media.licdn.com/dms/image/v2/D4D03AQE4ln1SA2wuJw/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1699708861619?e=1732752000&v=beta&t=KCV9uZVLPAjLksln9AK8JzotMlMf4AufgOP3sZbCnDM',
      link: 'https://www.linkedin.com/in/constanza-naso-66248462',
    },
    {
      id: 2,
      title: 'Yamila Palloni',
      subtitle: 'CEO & Co Founder @ Somos FIT',
      quote: 'Koi is the secret sauce that any startup would want to have.',
      direction: 'center',
      color: 'primary',
      img: 'https://media.licdn.com/dms/image/v2/C4D03AQG0kzrBXrfN0Q/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1659980959138?e=1732752000&v=beta&t=e9H8rY4Pgr7uDTlJI_hBwhtR8I1vmB-eRf2TdgHSyAI',
      link: 'https://www.linkedin.com/in/yamila-palloni-91397278',
    },
    {
      id: 3,
      title: 'Santiago Lacasia',
      subtitle: 'CEO & Co Founder @ Aconcagua',
      quote: 'A team that gives 100% to help us reach the top.',
      direction: 'center',
      color: 'secondary',
      img: 'https://media.licdn.com/dms/image/v2/C4D03AQGZLC6NFq2j9g/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1627062720066?e=1732752000&v=beta&t=P-MGs1jKT_pnXdBjqct2WOWzkT-T1XafFMYaPL7vDC0',
      link: 'https://www.linkedin.com/in/santiago-lacasia',
    },
    {
      id: 4,
      title: 'Candelaria Sosa',
      subtitle: 'Co Founder @ Tributo Simple',
      quote: 'A home away from home, the best place to start your dream.',
      direction: 'center',
      color: 'primary',
      img: 'https://media.licdn.com/dms/image/v2/C4D03AQFvI8Odfk38AA/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1660831412141?e=1732752000&v=beta&t=aQoulHOX1gaS_dljNRJzyWG6AEip5DeCy58zSaaqG_k',
      link: 'https://www.linkedin.com/in/mariacandelariasosa',
    },
  ];

  const CardsStyles = {
    overflow: 'hidden',
    width: '440px',
    height: 'auto',
    padding: '15px',
    display: 'flex',
    '@media (max-width: 720px)': {
      width: '100%',
    },
  };

  // Estilizar SwiperSlide usando styled-components de Material UI
  const StyledSwiperSlide = styled(SwiperSlide)(({ zIndex }) => ({
    ...CardsStyles,
    position: 'relative',
    marginLeft: '-160px', // Ajusta este valor para controlar el solapamiento
    zIndex,
    '&:first-of-type': {
      marginLeft: 0,
    },
    transition: 'transform 0.3s, z-index 0.3s',
    '&:hover': {
      zIndex: 9999, // Al hacer hover, la tarjeta tendrá el mayor z-index
    },
    '@media (max-width: 720px)': {
      marginLeft: 0,
    },
  }));

  return (
    <MKBox
      sx={{
        pb: { xs: 1, sm: 1, md: 5 },
      }}
      position="relative"
      alignItems="center"
    >
      <CustomContainer>
        <MKBox pb={5}>
          <Grid container spacing={1}>
            <Grid item xs={12} flexDirection="column">
              <MKTypography variant="h4" color="text">
                Testimonials
              </MKTypography>
            </Grid>
          </Grid>
        </MKBox>
        <MKBox sx={{ overflow: 'visible' }}>
          <Swiper slidesPerView="auto" spaceBetween={0} className="custom-swiper-container">
            {testimonials.map((testimonial, index) => (
              <StyledSwiperSlide
                key={testimonial.id}
                zIndex={testimonials.length - index} // Asignamos el zIndex de mayor a menor
              >
                <TestimonialCard
                  title={testimonial.title}
                  subtitle={testimonial.subtitle}
                  quote={testimonial.quote}
                  direction={testimonial.direction}
                  color={testimonial.color}
                  img={testimonial.img}
                  onClick={() => window.open(testimonial.link)}
                />
              </StyledSwiperSlide>
            ))}
          </Swiper>
        </MKBox>
      </CustomContainer>
    </MKBox>
  );
}

export default Testimonials;
