// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Grid from '@mui/material/Grid';

import { useTheme } from '@mui/material';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';
import MKAvatar from 'DS/components/MKAvatar';

import linkedinImg from 'assets/images/linkedin_black.png';

function TestimonialCard({
  title,
  subtitle,
  quote,
  twitter,
  direction,
  img,
  color,
  small,
  ...rest
}) {
  const Twitter = {
    '&::before': {
      content: '""',
      backgroundImage: `url(${linkedinImg})`,
      width: '22px',
      height: '17px',
      display: 'block',
      position: 'relative',
      float: 'left',
      margin: '0 10px 0 0',
    },
  };
  const quoteStyles = {
    ':before': {
      content: 'open-quote',
    },
    ':after': {
      content: 'close-quote',
    },
  };

  const theme = useTheme();

  return (
    <MKBox
      lineHeight={1}
      p={direction === 'center' ? 2 : 0}
      sx={({ palette: { gradients } }) => ({
        display: 'flex',
        width: '453px',
        p: '60px 40px',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: '25px',
        cursor: 'pointer',
        transition: 'all 150ms ease-in',
        gap: '53px',
        background: '#ffffff',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        '& .quote': {
          color: '#000',
        },
        '& .name': {
          color: theme.palette.primary.main,
        },
        '&:hover': {
          background: '#7D81ED',
          scale: 1.05,
          '& .quote': {
            color: '#fff',
          },
          '& .name': {
            color: '#000',
          },
        },
      })}
      {...rest}
    >
      <MKTypography className="quote">
        <span className="name">{title} said </span>
        {'"'}
        {quote}
        {'"'}
      </MKTypography>

      <Grid
        spacing={3}
        direction="row"
        justifyContent="start"
        alignItems="center"
        mt={3}
        display="flex"
        gap={2}
      >
        <MKAvatar
          src={img}
          alt={title}
          sx={{
            width: '70px',
            height: '70px',
          }}
        />
        <MKBox display="flex" alignItems="start" flexDirection="column">
          <MKBox display="flex" alignItems="start" flexDirection="column" gap="8px">
            <MKTypography
              className="titulo"
              sx={{ fontSize: '1.1rem' }}
              display="block"
              variant="name"
              fontWeight="bold"
            >
              {title}
            </MKTypography>
            <MKTypography display="block" variant="name" sx={{ fontSize: '1.1rem' }}>
              {subtitle}
            </MKTypography>
          </MKBox>
        </MKBox>
      </Grid>
    </MKBox>
  );
}

// Setting default props for the TestimonialCard
TestimonialCard.defaultProps = {
  color: 'info',
  direction: 'left',
  small: false,
};

// Typechecking props for the TestimonialCard
TestimonialCard.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark',
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(['left', 'right', 'center']),
  small: PropTypes.bool,
};

export default TestimonialCard;
