// src/components/Process.js

import React, { useRef, useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';
import { Box, Divider, List, ListItem, ListItemText, useTheme, alpha } from '@mui/material';

// Componentes personalizados
import CustomContainer from 'DS/components/Container';
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';

// Hook personalizado para manejar la coincidencia de consultas de medios
function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    const listener = () => setMatches(media.matches);
    media.addEventListener('change', listener);
    // Establecer el estado inicial
    setMatches(media.matches);
    return () => media.removeEventListener('change', listener);
  }, [query]);

  return matches;
}

const process = [
  {
    title: 'Open Call',
    description: 'Any startup who meets all the requirements and would like to join can apply!',
  },
  {
    title: 'Thesis Alignment',
    description: `Assessing if a startup's mission and operations match our selection criteria and impact goals.`,
  },
  {
    title: '1st Interview',
    description: `Primarily focuses on mutual introduction and gaining insights into the startup's operations and objectives.`,
  },
  {
    title: '2nd Interview',
    description:
      'The second interview delves deeper into discussing metrics, traction, challenges and program-specific details.',
  },
  {
    title: 'Pre-selection',
    description:
      'The Academy team will present those who they consider align with the thesis, the program requirements, and are the best-in-class entrepreneurs. A Selection Committee with KOI Partners, plus gathering opinions from experts and advisors, will conclude the pre-selection.',
  },
  {
    title: 'Final Selection',
    description:
      'We always run a quick but important due diligence before the final selection. As exhaustive as it can be given the startup stage. If nothing strange or wrong appears, you are selected!',
  },
];

function Process() {
  const isXs = useMediaQuery('(max-width: 720px)');
  const detailRefs = useRef([]);
  const theme = useTheme();

  // Opcional: Detectar el paso en vista para resaltar en la lista
  // Implementación simplificada
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      detailRefs.current.forEach((ref, index) => {
        if (ref) {
          const offsetTop = ref.offsetTop;
          const offsetHeight = ref.offsetHeight;
          if (
            scrollPosition >= offsetTop - 100 &&
            scrollPosition < offsetTop + offsetHeight - 100
          ) {
            // Aquí podrías resaltar el paso actual en la lista
            // Por simplicidad, esto no está implementado
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <MKBox
      sx={{
        width: { xs: '92%', sm: '92%' },
        display: 'grid',
        placeItems: 'center',
        px: { xs: 2, sm: 4, md: 6 },
        backgroundColor: alpha(theme.palette.primary.main, 0.2),
        p: { xs: 4, sm: 10 },
        mx: { xs: 'auto', sm: 'auto' },
        my: 10,
        borderRadius: '60px',
      }}
    >
      <CustomContainer sx={{ maxWidth: '1620px!important' }} maxWidth={false}>
        {/* Contenedor Principal */}
        <Grid
          container
          spacing={2}
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
          }}
        >
          {/* Columna Izquierda: Lista de Pasos */}
          <Grid item xs={12} sm={4}>
            {/* titulo */}
            <MKTypography
              variant="h4"
              sx={{
                fontWeight: 800,
                mb: 4,
                color: 'text',
                textAlign: 'center',
              }}
            >
              Selection Process
            </MKTypography>
          </Grid>

          {/* Barra Divisora */}
          <Grid item xs={12} sm={0.5}>
            <Divider
              orientation={isXs ? 'horizontal' : 'vertical'}
              flexItem
              sx={{
                borderColor: theme.palette.primary.main,
                my: { xs: 2, sm: 0 },
                width: '4px',
              }}
            />
          </Grid>

          {/* Columna Derecha: Detalles de los Pasos */}
          <Grid item xs={12} sm={7.5}>
            <Box
              sx={{
                height: '400px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box
                sx={{
                  maxHeight: '100%', // Altura de la scrollbar
                  overflowY: 'auto',
                  pr: 2,
                  '&::-webkit-scrollbar': {
                    width: '8px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.palette.primary.main, // Color del scrollbar
                    borderRadius: '4px',
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: alpha(theme.palette.primary.main, 0.1), // Color del track
                    borderRadius: '4px',
                  },
                }}
              >
                {process.map((item, index) => (
                  <Box
                    key={index}
                    sx={{ mb: index === process.length - 1 ? 0 : 7 }}
                    ref={(el) => (detailRefs.current[index] = el)}
                  >
                    <MKTypography
                      variant="h1"
                      sx={{
                        fontWeight: 900,
                        fontSize: '3.5rem',
                        mb: 1,
                        color: theme.palette.primary.main,
                      }}
                    >
                      {`0${index + 1}`}
                    </MKTypography>
                    <MKTypography variant="h3" color="text" sx={{ fontWeight: 600, mb: 1 }}>
                      {`${item.title}`}
                    </MKTypography>
                    <MKTypography variant="body1" color="textSecondary">
                      {item.description}
                    </MKTypography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CustomContainer>
    </MKBox>
  );
}

export default Process;
