function WhatsappButton() {
  return (
    <>
      <a
        target=" _blank"
        style={{
          borderRadius: '100%',
          padding: '20px',
          zIndex: 100,
          height: 100,
          marginBottom: 0,
          position: 'absolute',
          top: '-45px',
          right: 50,
        }}
        className="bg-green"
        href="https://wa.me/+5491167161749?text=Hola Koi"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="60"
          height="60"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            fill="#FFFF"
            fillRule="evenodd"
            d="M18.378 5.628C16.685 3.935 14.435 3 12.038 3c-4.94 0-8.962 4.02-8.962 8.959-.003 1.58.41 3.12 1.195 4.48L3 21.082l4.752-1.248c1.307.715 2.783 1.09 4.283 1.093h.003c4.94 0 8.96-4.02 8.962-8.962 0-2.394-.932-4.644-2.622-6.337zm-6.34 13.784h-.003c-1.336 0-2.648-.36-3.79-1.037l-.273-.161-2.822.738.753-2.748-.175-.281c-.748-1.187-1.14-2.558-1.14-3.964 0-4.105 3.343-7.444 7.453-7.444 1.99 0 3.858.776 5.265 2.182 1.406 1.41 2.18 3.279 2.18 5.268 0 4.107-3.343 7.447-7.448 7.447zm4.084-5.578c-.223-.111-1.324-.653-1.53-.727-.204-.076-.354-.11-.503.112-.15.225-.577.73-.71.879-.128.146-.26.167-.482.055-.226-.111-.947-.348-1.802-1.113-.665-.592-1.116-1.327-1.245-1.55-.132-.225-.015-.345.096-.457.103-.1.226-.26.337-.392.112-.13.15-.223.226-.372.073-.15.035-.282-.02-.393-.056-.111-.505-1.216-.692-1.664-.182-.437-.366-.375-.504-.384-.129-.006-.278-.006-.428-.006-.15 0-.392.056-.597.282-.206.222-.783.764-.783 1.866 0 1.101.803 2.168.914 2.317.112.147 1.58 2.408 3.827 3.378.533.232.949.37 1.274.472.536.17 1.025.146 1.412.09.43-.064 1.324-.542 1.512-1.066.184-.521.184-.97.129-1.063-.056-.094-.205-.15-.43-.264z"
            clipRule="evenodd"
          />
        </svg>
      </a>
    </>
  );
}

export default WhatsappButton;
