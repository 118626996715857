// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import CustomContainer from 'DS/components/Container';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKButton from 'DS/components/MKButton';
import MKTypography from 'DS/components/MKTypography';

// Growth
import enlite from 'assets/images/logos/Enlite (2).png';
import ophta from 'assets/images/logos/growth/Logo-ophta.png';
import rene from 'assets/images/logos/growth/Logo-rene.png';
import revil from 'assets/images/logos/growth/Logo-revil.png';
import tell from 'assets/images/logos/growth/Logo-Tell.png';

function AlumniBootcamp() {
  const logosGrowth = [
    {
      image: enlite,
      name: 'Enlite',
      url: 'https://enlite.health/es/ar/',
    },
    {
      image: ophta,
      name: 'Ophta',
      url: 'https://eyehealthvr.com.ar/',
    },
    {
      image: rene,
      name: 'Hola René',
      url: 'https://holarene.com/',
    },
    {
      image: revil,
      name: 'Reliv',
      url: 'https://reliv.la',
    },
    {
      image: tell,
      name: 'Tell',
      url: 'https://tellapp.org/',
    },
  ];
  const BoxLogo = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#FFF',
    width: '160px',
    height: '160px',
    boxShadow: '0px 2px 64px rgba(8, 0, 97, 0.08)',
    borderRadius: '5px',
    border: '2px solid #FFF',
    transition: 'all 150ms ease-in',
    '&:hover': {
      border: '2px solid #DB6639',
      boxShadow: '-6px 6px 0px 0px #DB6639',
      marginTop: '-6px',
      marginLeft: '6px',
      marginRight: '-6px',
    },
  };
  return (
    <MKBox display="flex">
      <CustomContainer>
        <MKBox pt={2} pb={8} textAlign="center">
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={12} lg={7} alignItems="center" flexDirection="column">
              <MKTypography variant="h1" color="text" mb={1}>
                Alumni
              </MKTypography>
            </Grid>
          </Grid>
        </MKBox>
        <MKBox
          display="flex"
          flexDirection="row"
          gap="16px"
          justifyContent="center"
          flexWrap="wrap"
          style={{ cursor: 'pointer' }}
        >
          {logosGrowth &&
            logosGrowth.map((item) => (
              <MKBox
                sx={{
                  ...BoxLogo,
                }}
                onClick={() => {
                  window.open(item.url);
                }}
              >
                {item.image ? (
                  <MKBox
                    className="img-card"
                    component="img"
                    src={item.image}
                    alt={item.name}
                    width="85px"
                    position="relative"
                    zIndex={1}
                    top="auto"
                  />
                ) : (
                  <MKTypography variant="h4" color="text" mb={1}>
                    {item.name}
                  </MKTypography>
                )}
              </MKBox>
            ))}
        </MKBox>
      </CustomContainer>
    </MKBox>
  );
}

export default AlumniBootcamp;
