// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import CustomContainer from 'DS/components/Container';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKButton from 'DS/components/MKButton';
import MKTypography from 'DS/components/MKTypography';

// Incubation
import EiouAcademy from 'assets/images/logos/incubation/Eiou Academy.jpeg';
import TalentSense from 'assets/images/logos/incubation/Talent Sense.jpeg';
import Telly from 'assets/images/logos/incubation/Telly.jpeg';
import UPFC from 'assets/images/logos/incubation/UPFC.jpeg';
import Vecinos from 'assets/images/logos/incubation/Vecinos.jpeg';
import Waste from 'assets/images/logos/incubation/Waste.jpeg';
import Appapacho from 'assets/images/logos/incubation/appapacho.png';
import diamanthum from 'assets/images/logos/incubation/diamanthum.png';
import eCommitment from 'assets/images/logos/incubation/eCommitment.jpeg';
import impactu from 'assets/images/logos/incubation/impactu.png';
import acuerdo from 'assets/images/logos/incubation/logo-acuerdo.webp';
import olistik from 'assets/images/logos/incubation/logo-olistik.webp';
import uFeedback from 'assets/images/logos/incubation/uFeedback.jpeg';
import vonfire from 'assets/images/logos/incubation/vonfire.png';
import Button from 'assets/theme/components/button';

function AlumniBootcamp() {
  const logosMap = [
    {
      image: acuerdo,
      name: 'Acuerdo Sencillo',
      url: 'https://www.acuerdosencillo.com/',
    },
    {
      image: Appapacho,
      name: 'Appapacho',
      url: 'https://www.appapacho.com.ar/',
    },
    {
      image: eCommitment,
      name: 'eCommitment',
      url: 'https://www.ecommitment.online/',
    },
    {
      image: EiouAcademy,
      name: 'Eiou Academy',
      url: 'https://eiouacademy.com/',
    },
    {
      image: impactu,
      name: 'Impactu',
      url: 'https://impactuhabits.com/',
    },
    {
      image: olistik,
      name: 'Olistik',
      url: 'https://olistik.life',
    },
    {
      image: TalentSense,
      name: 'Talent Sense',
      url: 'https://talentsense.com/',
    },
    {
      image: Telly,
      name: 'Telly',
      url: 'https://telly.bot/',
    },
    {
      image: UPFC,
      name: 'UPFC',
      url: 'https://upfcoin.org/',
    },
    {
      image: uFeedback,
      name: 'uFeedback',
      url: 'https://ufeedback.xyz/',
    },
    {
      image: Vecinos,
      name: 'Vecinos',
      url: 'https://vecinos.com/',
    },
    {
      image: vonfire,
      name: 'Vonfire',
      url: 'https://vonfire.co/',
    },
    {
      image: Waste,
      name: 'Waste',
      url: 'https://waste.com/',
    },
  ];
  const BoxLogo = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#FFF',
    width: '160px',
    height: '160px',
    boxShadow: '0px 2px 64px rgba(8, 0, 97, 0.08)',
    borderRadius: '10px',
    border: '2px solid #FFF',
    transition: 'all 150ms ease-in',
    '&:hover': {
      border: '2px solid #DB6639',
      boxShadow: '-6px 6px 0px 0px #DB6639',
      marginTop: '-6px',
      marginLeft: '6px',
      marginRight: '-6px',
    },
  };
  return (
    <MKBox display="flex" py={10}>
      <CustomContainer>
        <MKBox pt={2} pb={3} textAlign="center">
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={12} lg={7} alignItems="center" flexDirection="column">
              <MKTypography variant="h1" color="text" mb={1}>
                Alumni
              </MKTypography>
            </Grid>
          </Grid>
        </MKBox>
        <MKBox
          display="flex"
          flexDirection="row"
          gap="16px"
          justifyContent="center"
          flexWrap="wrap"
          style={{ cursor: 'pointer' }}
        >
          {logosMap &&
            logosMap.map((item) => (
              <MKBox
                sx={{
                  ...BoxLogo,
                }}
                onClick={() => {
                  window.open(item.url);
                }}
              >
                {item.image ? (
                  <MKBox
                    className="img-card"
                    component="img"
                    src={item.image}
                    alt={item.name}
                    width="85px"
                    position="relative"
                    zIndex={1}
                    top="auto"
                  />
                ) : (
                  <MKTypography variant="h4" color="text" mb={1}>
                    {item.name}
                  </MKTypography>
                )}
              </MKBox>
            ))}
        </MKBox>
        {/* <MKBox pt={6} pb={8} textAlign="center">
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={12} lg={7} alignItems="center" flexDirection="column">
              <MKTypography variant="body1" color="text">
                Aconcagua provides lenders the latest tech-based collaterals. This new paradigm of
                lending will: 1) increase the base of clients; 2) increase the credit size; 3)
                decrease the interest rate.
              </MKTypography>
            </Grid>
          </Grid>
        </MKBox> */}
      </CustomContainer>
    </MKBox>
  );
}

export default AlumniBootcamp;
