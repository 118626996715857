import Grid from '@mui/material/Grid';
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';
import CustomContainer from '../../../DS/components/Container';

import miche from 'assets/images/team/micheCeo.png';
import mili from 'assets/images/team/mili.png';
import pablo from 'assets/images/team/pablo.png';

function TeamCeo() {
  const data = [
    {
      name: 'Michel Abdala',
      role: 'CTO & Co-Founder',
      description: `With over a decade's experience at Accenture and Banco Galicia where he led teams of +50 developers, alongside co-founding experiences like Hola René and Yodai Tech, Michel showcases a strong understanding of technology and entrepreneurship as a whole.`,
      image: miche,
    },
    // {
    //   name: 'Pablo Gutierrez',
    //   role: 'CEO',
    //   description: `General Partner at Kalei Ventures, Pablo is an entrepreneur and inventor with extensive experience gained at Techstars, BAIN & Company, and Banco Galicia, coupled with an MBA from Columbia Business School.`,
    //   image: pablo,
    // },
    {
      name: 'Mili Gutierrez',
      role: 'COO',
      description: `Bringing a wealth of practical knowledge from BAIN & Company, Grupo Pegasus, and successful ventures like Surfing Tsunamis and Kamay Consultoría, Milagros pairs her Industrial Engineering degree from ITBA (ARG) with a strong entrepreneurial mindset.`,
      image: mili,
    },
  ];
  const CEOsSection = {
    display: 'flex',
    width: { xs: '100%', sm: '100%', md: '100%' },
    flexDirection: 'column',
    textAlign: 'center',
    gap: '50px',
    marginTop: '50px',
    '@media (min-width: 600px)': {
      flexDirection: 'row',
      justifyContent: 'center',
    },
  };
  const CEOCircle = {
    width: '150px',
    height: '150px',
    borderRadius: '50%',
    overflow: 'hidden',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    marginLeft: 'auto',
    marginRight: 'auto',
  };

  const CEOImage = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  };

  const CEOInfo = {
    textAlign: 'center',
    marginTop: '10px',
    marginBottom: '10px',
  };

  const CEOCard = {
    display: 'flex',
    flexDirection: 'column',
    width: { xs: '100%', sm: '100%', md: '300px' },
    height: '100%',
    padding: '20px',
    borderRadius: '10px',
    backgroundColor: '#fff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    '&:hover': {
      transition: 'all 150ms ease-in',
      border: '2px solid #DB6639',
      boxShadow: '-6px 6px 0px 0px #DB6639',
      marginTop: '-6px',
      marginLeft: '6px',
      marginRight: '-6px',
    },
  };

  return (
    <MKBox display="flex" sx={{ pt: { xs: 1, sm: 1, md: 6 } }}>
      <CustomContainer>
        <MKTypography variant="h1" color="text" mb={1} style={{ textAlign: 'center' }}>
          <b>Who</b> we are
        </MKTypography>
        <MKBox sx={CEOsSection}>
          {data.map((CEO) => (
            <Grid item key={CEO.name}>
              <MKBox sx={CEOCard}>
                <MKBox sx={CEOCircle}>
                  <img src={CEO.image} alt={CEO.name} style={CEOImage} />
                </MKBox>
                <MKBox sx={CEOInfo}>
                  <MKTypography
                    variant="body1"
                    component="p"
                    color="text"
                    sx={{ marginBottom: '10px', fontweight: '800' }}
                  >
                    {CEO.name}
                  </MKTypography>
                  <MKTypography
                    variant="body2"
                    component="p"
                    color="text"
                    sx={{ marginBottom: '20px' }}
                  >
                    {CEO.role}
                  </MKTypography>
                  <MKTypography variant="body2" component="p" color="text">
                    {CEO.description}
                  </MKTypography>
                </MKBox>
              </MKBox>
            </Grid>
          ))}
        </MKBox>
      </CustomContainer>
    </MKBox>
  );
}

export default TeamCeo;
