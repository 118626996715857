// src/components/Portfolio.js

import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import { Box, Button } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import CustomContainer from 'DS/components/Container';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKButton from 'DS/components/MKButton';
import MKTypography from 'DS/components/MKTypography';

// importar empresas desde el context
import { logosAcceleration, logosGrowth, logosIncubation, logosTech } from 'contexts/companies';

const categories = ['Aceleración', 'Incubación', 'Growth'];

function Portfolio() {
  const [arrayIndex, setArrayIndex] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const logosByCategory = [logosTech];
  const currentLogos = logosByCategory[arrayIndex] || logosTech;

  // Combinar los logos de todas las categorías para el carrusel
  const allLogos = [...logosTech];
  // Estilos para cada logo de empresa
  const BoxLogo = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'transparent',
    width: '130px',
    height: '130px',
    boxShadow: 'none',
    borderRadius: '10px',
    transition: 'all 150ms ease-in',
    flexShrink: 0,
    mx: 5,
    '&:hover': {
      cursor: 'pointer',
      scale: 1.1,
    },
  };

  return (
    <section id="portfolio">
      <MKBox display="flex" position="relative" id="PortfolioAcademy">
        <CustomContainer>
          {/* Título */}
          <MKBox py={10} textAlign={'center'}>
            <Grid container spacing={1} justifyContent="center">
              <Grid item xs={12} lg={7} alignItems="center" flexDirection="column">
                <MKTypography
                  variant="h4"
                  align="center"
                  color="text"
                  sx={{
                    fontWeight: 400,
                    letterSpacing: 1,
                    marginBottom: 4,
                  }}
                >
                  <b>Who</b> we work with
                </MKTypography>
              </Grid>
            </Grid>
          </MKBox>

          <MKBox
            sx={{
              overflow: 'hidden',
              position: 'relative',
              width: '100%',
            }}
          >
            <MKBox
              sx={{
                display: 'flex',
                width: 'max-content',
                animation: 'scroll 60s linear infinite',
                '@keyframes scroll': {
                  '0%': { transform: 'translateX(0)' },
                  '100%': { transform: 'translateX(-50%)' },
                },
              }}
            >
              {/* Duplicar los logos para crear el efecto de loop */}
              {[...allLogos, ...allLogos].map((item, index) => (
                <MKBox
                  key={index}
                  sx={{
                    ...BoxLogo,
                  }}
                  onClick={() => window.open(item.url, '_blank')}
                >
                  {item.image ? (
                    <MKBox
                      component="img"
                      src={item.image}
                      alt={item.name}
                      sx={{
                        width: '150px',
                        height: '100%',
                        objectFit: 'contain',
                        p: '15px',
                      }}
                    />
                  ) : (
                    <MKTypography variant="h4" color="text" mb={1}>
                      {item.name}
                    </MKTypography>
                  )}
                </MKBox>
              ))}
            </MKBox>
          </MKBox>
        </CustomContainer>
      </MKBox>
    </section>
  );
}

export default Portfolio;
