// Importaciones necesarias
import React, { useRef, useState, useEffect } from 'react';
import { useTheme, keyframes } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { alpha } from '@mui/material/styles';
import CustomContainer from 'DS/components/Container';
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';
// Imágenes
import investorAnalysis from 'assets/images/icons/investor-analysis.svg';
import investorEcosystem from 'assets/images/icons/investor-ecosystem.svg';
import investorLp from 'assets/images/icons/investor-lp.svg';
import investorTraining from 'assets/images/icons/investor-training.svg';

const wobble = keyframes`
  0% {
    transform: translateY(0);
  }
  15% {
    transform: translateY(-10px);
  }
  30% {
    transform: translateY(10px);
  }
  45% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(10px);
  }
  75% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
`;

// Definimos los estilos de BoxLogo
const BoxLogo = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  textAlign: 'flex-start',
  gap: '24px',
  backgroundColor: alpha('#DB6639', 0.1),
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: { xs: '100%', md: '24%' },
  height: '402px',
  boxShadow: '0px 2px 8px rgba(8, 0, 97, 0.08)',
  borderRadius: '10px',
  padding: '16px',
  transition: 'all 150ms ease-in',
  '&:hover': {
    animation: `${wobble} 1s ease-in-out`,
  },
};

function CardComponent({ item, isMobile, index }) {
  const cardRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target); // Deja de observar una vez que es visible
        }
      },
      { threshold: 0.1 }
    );
    if (cardRef.current) {
      observer.observe(cardRef.current);
    }
    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, []);

  return (
    <MKBox
      ref={cardRef}
      sx={{
        ...BoxLogo,
        mt: !isMobile && index % 2 === 0 ? 0 : 8,
        transform: isVisible ? 'translateY(0)' : 'translateY(100px)',
        opacity: isVisible ? 1 : 0,
        transition: 'all 0.6s ease-out',
      }}
    >
      <MKBox component="img" src={item.iconPath} maxWidth="3rem" />
      <MKTypography
        variant="h6"
        color="text"
        mb={1}
        sx={{ fontSize: { xs: '1rem', md: 'inherit' } }}
      >
        {item.name}
      </MKTypography>
      <MKTypography
        variant="body3"
        color="text"
        mb={1}
        sx={{ fontSize: { xs: '1rem', md: 'inherit' } }}
      >
        {item.description}
      </MKTypography>
    </MKBox>
  );
}

function WhatWeDo() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const cardMap = [
    {
      iconPath: investorTraining,
      name: 'Investor training',
      description:
        'Access to workshops related to investments and fundraising with experts, including both proprietary and third-party courses.',
    },
    {
      iconPath: investorLp,
      name: 'LP Network',
      description:
        'We add you to our network of contacts, invite you to our events and ecosystem events, and to regional events of interest.',
    },
    {
      iconPath: investorEcosystem,
      name: 'Ecosystem Connection',
      description:
        'Close contact with startups from our portfolio, and access to startups, mentors, and institutions within the ecosystem.',
    },
    {
      iconPath: investorAnalysis,
      name: 'Deal-flow Analysis and Access',
      description:
        'We help you analyze deal-flow and the startups you bring to guide you through your first investments..',
    },
  ];

  return (
    <>
      <MKBox display="flex" position="relative" padding={2} margin={'40px'}>
        <CustomContainer>
          {/* Título */}
          <MKTypography
            variant="h4"
            align="start"
            color="text"
            sx={{
              fontWeight: 400,
              letterSpacing: 1,
              marginBottom: 1,
            }}
          >
            <b>What </b>we do
          </MKTypography>

          <MKTypography
            sx={{ fontWeight: '600px', color: theme.palette.grey[600], fontSize: '31px' }}
          >
            We are a gateway to invest in startups
          </MKTypography>

          {/* Contenedor de Tarjetas */}
          <MKBox
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            gap="16px"
            justifyContent={{ xs: 'center', md: 'space-between' }}
            alignItems="center"
            flexWrap="wrap"
            py={5}
          >
            {cardMap.map((item, index) => (
              <CardComponent key={index} item={item} isMobile={isMobile} index={index} />
            ))}
          </MKBox>
        </CustomContainer>
      </MKBox>
      {/* ... (resto de tu código) */}
      <MKBox
        display="flex"
        flexDirection="column"
        gap="24px"
        position="relative"
        alignItems={'center'}
        padding={2}
        margin={'40px'}
      >
        <MKTypography
          sx={{ fontWeight: '600px', color: theme.palette.grey[600], fontSize: '31px' }}
        >
          Available options for investing
        </MKTypography>

        <MKBox
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: '10px',
            alignItems: 'center',
          }}
        >
          <MKBox
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              border: '2px #DB6639 solid',
              borderRadius: { xs: '10px', md: '30px' },
              padding: { xs: '0px 10px', md: '13px 30px' },
              alignItems: 'center',
              transition: 'all 150ms ease-in',
              '&:hover': {
                boxShadow: '0px 2px 8px rgba(8, 0, 97, 0.3)',
                transform: 'scale(1.05)',
                backgroundColor: alpha('#DB6639', 1),
              },
              '&:hover #investInPortfolio': {
                color: '#fff',
              },
            }}
          >
            <MKTypography
              id="investInPortfolio"
              variant="h6"
              color="text"
              sx={{ fontSize: { xs: '1rem', md: 'inherit' }, color: '#DB6639' }} // Ajuste de tamaño en móvil
            >
              In portfolio startups
            </MKTypography>
          </MKBox>

          <MKTypography
            variant="h6"
            color="text"
            mb={{ xs: 0, md: 1 }}
            sx={{ fontSize: { xs: '1rem', md: 'inherit' } }} // Ajuste de tamaño en móvil
          >
            or/and
          </MKTypography>

          <MKBox
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              border: '2px #DB6639 solid',
              borderRadius: { xs: '10px', md: '30px' },
              padding: { xs: '0px 10px', md: '13px 30px' },
              alignItems: 'center',
              transition: 'all 150ms ease-in',
              '&:hover': {
                boxShadow: '0px 2px 8px rgba(8, 0, 97, 0.3)',
                transform: 'scale(1.05)',
                backgroundColor: alpha('#DB6639', 1),
              },
              '&:hover #investInPortfolio': {
                color: '#fff',
              },
            }}
          >
            <MKTypography
              id="investInPortfolio"
              variant="h6"
              color="text"
              sx={{ fontSize: { xs: '1rem', md: 'inherit' }, color: '#DB6639' }} // Ajuste de tamaño en móvil
            >
              in our Rolling Fund
            </MKTypography>
          </MKBox>
        </MKBox>
      </MKBox>
    </>
  );
}

export default WhatWeDo;
