// Loader.js

import React, { useState, useEffect } from 'react';
import { alpha, Box } from '@mui/material';
import Logo from 'assets/images/logos/logoWhite.svg';
import { keyframes, useTheme } from '@mui/material';

const Loader = ({ onFinish }) => {
  const theme = useTheme();
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    // Inicia la animación después de 2 segundos
    const timer = setTimeout(() => {
      setIsAnimating(true);
    }, 1500);

    // Finaliza el loader después de que termine la animación (duración de la animación: 2 segundos)
    const finishTimer = setTimeout(() => {
      onFinish();
    }, 3000); // 2 segundos de espera + 2 segundos de animación

    return () => {
      clearTimeout(timer);
      clearTimeout(finishTimer);
    };
  }, [onFinish]);

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9999,
        overflow: 'hidden',
        transition: 'background-color 1s ease-out',
      }}
    >
      <Box
        component="img"
        src={Logo}
        alt="Logo"
        sx={{
          width: isAnimating ? '8850vw' : '150px', // Agranda el logo a 150% del viewport width
          height: 'auto',
          transition: 'width 2s ease-in-out, transform 2s ease-in-out',
          transform: isAnimating
            ? 'translate(-55%, -55%) scale(1)'
            : 'translate(-50%, -50%) scale(1)',
          position: 'absolute',
          top: '50%',
          left: '50%',
        }}
      />
      {/* Opcional: Añadir una superposición para el efecto de transición */}
      {isAnimating && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '150vw',
            height: '150vh',
            backgroundColor: '#fff',
            zIndex: 2,
            opacity: 0,
            animation: 'fadeIn 2s forwards',
          }}
        />
      )}
    </Box>
  );
};

export default Loader;
