// src/components/InfoBanner.js
import { useState, useEffect } from 'react';
import React from 'react';
import { useTheme } from '@mui/material';
import CustomContainer from 'DS/components/Container';
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import { CircularProgress, Grid, Typography, Box } from '@mui/material';

function InfoBanner() {
  const theme = useTheme();

  // Hook para detectar si la sección está en vista
  const { ref, inView } = useInView({
    threshold: 0.5, // Se considera en vista cuando el 30% está visible
    triggerOnce: true, // Solo activar una vez
  });

  // Datos para las métricas
  const metricsData = [
    { label: 'Startups', end: 44 },
    { label: 'NPS', end: 9 },
    { label: 'Female Founders', end: 51, isPercentage: true },
    // { label: 'NPS', end: 9 },
    // { label: 'Startups vivas', end: 40, isPercentage: true },
  ];

  const animateProgress = (startValue, setProgress) => {
    let progress = startValue;
    const interval = setInterval(() => {
      if (progress >= 100) {
        clearInterval(interval);
      } else {
        progress += 1;
        setProgress(progress);
      }
    }, 10); // Ajusta la velocidad del incremento
  };

  return (
    <MKBox
      py={{ xs: 5, sm: 10 }}
      width="100%"
      sx={{ display: 'grid', placeItems: 'center' }}
      px={{ xs: 2, lg: 2 }}
      ref={ref} // Referencia para detectar el scroll
    >
      <CustomContainer sx={{ maxWidth: '1620px!important' }} maxWidth={false}>
        {/* Título de la Sección */}
        <Grid
          container
          spacing={2}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            pl: 0, // Padding Left
            pr: 0, // Padding Right
            pt: '16px', // Padding Top
            pb: 0, // Padding Bottom
            justifyContent: 'center',
            mt: { xs: '20px', sm: '60px' },
          }}
        >
          <Grid item xs={12} sx={{ textAlign: 'start' }}>
            <MKTypography
              sx={{
                fontWeight: '600px',
                color: theme.palette.grey[600],
                fontSize: '31px',
                mb: '10px',
              }}
            >
              Take a look at our track record...
            </MKTypography>
            <MKTypography
              variant="h4"
              align="start"
              color="text"
              sx={{
                fontWeight: 400,
                letterSpacing: 1,
                marginBottom: 4,
              }}
            >
              <b>What we’ve achieved</b>
            </MKTypography>
          </Grid>
        </Grid>

        {/* Métricas */}
        <Grid
          container
          spacing={4}
          sx={{
            mt: { xs: '20px', sm: '10px' },
            px: { xs: 1, lg: 0 },
            width: '100%',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          {metricsData.map((item, index) => {
            const [progress, setProgress] = useState(item.end); // Inicia en el valor de item.end

            useEffect(() => {
              if (inView) {
                animateProgress(item.end, setProgress);
              }
            }, [inView, item.end]);

            return (
              <Grid
                key={index}
                item
                xs={10}
                sm={3}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: '0px',
                  margin: '0px',
                }}
              >
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                  <CircularProgress variant="determinate" value={progress} size={'180px'} />
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography
                      variant="caption"
                      component="div"
                      sx={{ color: '#5D5DFB', fontSize: '65px' }}
                    >
                      {`${Math.round(item.end)}${item.isPercentage ? '%' : ''}`}
                    </Typography>
                    <Typography
                      variant="caption"
                      component="div"
                      sx={{ color: '#5D5DFB', fontSize: '15px' }}
                    >
                      {item.label}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </CustomContainer>
    </MKBox>
  );
}

export default InfoBanner;
