// @mui material components
import { Grid } from '@mui/material/';
import { useEffect, useState } from 'react';
import { alpha } from '@mui/material/styles';

import CustomContainer from 'DS/components/Container';

// SwiperJS styles
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/swiper.min.css';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';
import { keyframes } from '@mui/material/styles'; // Importa keyframes de MUI

// Definir la animación para mover el gradiente
const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const metricsData = [
  { value: 'USD 3k', label: 'Minimum ticket' },
  { value: '5x', label: 'Expected return (5/7 years)' },
  { value: `2 Q's`, label: 'Minimum' },
];

function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [matches, query]);

  return matches;
}

// comienzo del componente
function Requirements() {
  const isXs = useMediaQuery('(max-width: 720px)');

  const BoxLogo = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '200px',
    height: '200px',
    boxShadow: '0px 2px 64px rgba(8, 0, 97, 0.08)',
    borderRadius: '10px',
    padding: '16px',
    border: '1px solid #FFF',
    transition: 'all 150ms ease-in',
    '&:hover': {
      boxShadow: '0px 2px 64px rgba(8, 0, 97, 0.3)',
      transform: 'scale(1.05)',
    },
  };

  return (
    <MKBox display="flex" position="relative" padding={2} margin={'40px'}>
      <CustomContainer>
        <MKTypography
          variant="h4"
          align="start"
          color="text"
          sx={{
            fontWeight: 400,
            letterSpacing: 1,
            marginBottom: 1,
          }}
        >
          Requirements
        </MKTypography>

        <MKBox pt={8} pb={5} textAlign="center">
          <Grid
            container
            spacing={1}
            justifyContent="space-around"
            alignItems="start"
            sx={{
              border: '1px dotted #D9D9D9',
              borderRadius: '30px',
              gap: '125px',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              p: '30px 40px',
              background: `linear-gradient(270deg, transparent, ${alpha(
                '#DB6639',
                0.1
              )} ,transparent)`,
              backgroundSize: '400% 400%',
              animation: `${gradientAnimation} 8s ease infinite`,
              transition: 'all 0.9s ease',
              '&:hover': {
                scale: 1.01,
              },
              '@media (max-width: 720px)': {
                pb: 5,
                pt: 5,
              },
            }}
          >
            {metricsData.map((metric, index) => (
              <Grid
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'row' },
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                xs={12}
                sm={3}
              >
                <MKTypography
                  variant="h2"
                  align="center"
                  fontWeight="bold"
                  sx={{
                    mr: 2,
                    fontSize: { xs: '2rem', sm: '3rem' },
                  }}
                >
                  {metric.value}
                </MKTypography>
                <MKTypography variant="h6" align="center" fontWeight="light">
                  {metric.label}
                </MKTypography>
              </Grid>
            ))}
          </Grid>
        </MKBox>
      </CustomContainer>
    </MKBox>
  );
}

export default Requirements;
