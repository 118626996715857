// src/components/HoverCard.js

import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import MKTypography from 'DS/components/MKTypography'; // Asegúrate de que esta ruta sea correcta

const HoverCard = ({ title, description, cta, backgroundColor, image }) => {
  return (
    <Box
      className="hover-card"
      sx={{
        position: 'relative',
        width: '199px',
        height: '520px',
        backgroundImage: `url(${image})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        borderRadius: '12px',
        boxShadow: 3,
        overflow: 'hidden',
        transition: 'width 0.3s ease',
        p: '50px 20px',
        cursor: 'pointer',
        // Estilos al hacer hover en la tarjeta
        '&:hover': {
          width: { xs: '300px', md: '432px' },
        },
        // Estilos para los elementos hijos al hacer hover
        '&:hover .hover-card-title': {
          transform: 'translate(0, -220px) rotate(-0deg)', // Mueve al origen y rota
          // si la descripcion tiene mas de 80 caracteres cambio cuanto tiene de alto
          top: description.length > 83 ? 'calc(100% - 45px)' : 'calc(100% - 20px)',
          left: '20px', // 20px desde la izquierda
        },
        '&:hover .hover-card-content': {
          opacity: 1,
          pointerEvents: 'auto',
        },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Color oscuro semitransparente
          zIndex: 1, // Asegúrate de que la superposición esté por encima de la imagen
        }}
      />
      {/* Título Inicialmente Centrado */}
      <Typography
        variant="h3"
        className="hover-card-title"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%) rotate(-90deg)', // Centrado y sin rotación
          transformOrigin: 'center',
          whiteSpace: 'nowrap',
          transition: 'all 0.3s ease',
          color: '#fff',
          textAlign: 'center',
          zIndex: 2, // Asegúrate de que el título esté por encima de la superposición
        }}
      >
        {title}
      </Typography>

      {/* Contenedor para el Contenido que Aparece al Hacer Hover */}
      <Box
        sx={{
          position: 'absolute',
          bottom: '40px',
          left: '20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: 1,
        }}
      >
        {/* Contenido que aparece al hacer hover */}
        <Box
          className="hover-card-content"
          sx={{
            opacity: 0,
            transition: 'opacity 0.3s ease',
            textAlign: 'left',
            zIndex: 1,
            pointerEvents: 'none',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            width: '90%',
          }}
        >
          <Typography sx={{ color: '#fff', fontSize: '25px' }}>{description}</Typography>
          {/* <Button
            variant="contained"
            color="primary"
            sx={{
              borderRadius: '10px',
              backgroundColor: '#DB6639',
              color: '#fff',
              width: '136px',
              p: '10px 20px',
              '&:hover': {
                backgroundColor: '#DB6639',
                color: '#fff',
              },
            }}
          >
            {cta}
          </Button> */}
        </Box>
      </Box>
    </Box>
  );
};

export default HoverCard;
