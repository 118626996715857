import { useEffect, useState } from 'react';

// react-router components
import { Link } from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui material components

import CustomContainer from 'DS/components/Container';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';

// Material Kit 2 PRO React examples

// Material Kit 2 PRO React base styles
import breakpoints from 'assets/theme/base/breakpoints';

import Logo from 'assets/images/Logo.png';

const BRAND_NAME = 'Koi Ventures';

function HeaderVentures({ transparent, light, action, sticky, relative, center }) {
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  const toggleMobileNavbar = () => setMobileNavbar(!mobileNavbar);

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }

    /**
     The event listener that's calling the displayMobileNavbar function when
     resizing the window.
    */
    window.addEventListener('resize', displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', displayMobileNavbar);
  }, []);

  return (
    <CustomContainer sx={sticky ? { position: 'sticky', top: 0, zIndex: 10 } : null}>
      <MKBox
        py={6}
        px={{ xs: 2, sm: 2, lg: 2 }}
        width="100%"
        shadow={transparent ? 'none' : 'md'}
        color={light ? 'white' : 'dark'}
        position={relative ? 'relative' : 'absolute'}
        left={0}
        zIndex={3}
        sx={({ palette: { transparent: transparentColor, white }, functions: { rgba } }) => ({
          backgroundColor: transparent ? transparentColor.main : rgba(white.main, 0.8),
          backdropFilter: transparent ? 'none' : `saturate(200%) blur(30px)`,
        })}
      >
        <MKBox
          display="flex"
          justifyContent={{ xs: 'space-between', md: 'start' }}
          alignItems="center"
        >
          <MKBox
            component={Link}
            to="/"
            lineHeight={1}
            // py={transparent ? 1.5 : 0.75}
            pl={relative || transparent ? 0 : { xs: 0, lg: 1 }}
            display="flex"
            order={{ xs: 1, sm: 1, md: 0 }}
            style={{ maxWidth: 65 }}
          >
            <MKBox
              component="img"
              src={Logo}
              alt={BRAND_NAME}
              position="relative"
              zIndex={1}
              sx={{
                height: 40,
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '100%',
                },
              }}
            />
          </MKBox>
        </MKBox>
      </MKBox>
    </CustomContainer>
  );
}

// Setting default values for the props of DefaultNavbar
HeaderVentures.defaultProps = {
  transparent: false,
  light: false,
  action: false,
  sticky: false,
  relative: false,
  center: false,
};

// Typechecking props for the DefaultNavbar
HeaderVentures.propTypes = {
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(['external', 'internal']).isRequired,
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        'primary',
        'secondary',
        'info',
        'success',
        'warning',
        'error',
        'dark',
        'light',
        'default',
        'white',
      ]),
      label: PropTypes.string.isRequired,
    }),
  ]),
  sticky: PropTypes.bool,
  relative: PropTypes.bool,
  center: PropTypes.bool,
};

export default HeaderVentures;
