import { Icon } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CustomContainer from 'DS/components/Container';
import MKBox from 'DS/components/MKBox';
import MKButton from 'DS/components/MKButton';
import MKTypography from 'DS/components/MKTypography';

function CardDescription() {
  // Array con la información de los logos
  const logosMap = [
    { name: '1:1 Mentorship' },
    { name: 'Fundraising' },
    { name: 'Workshops' },
    { name: 'Fireside chats with Experts' },
    { name: 'Community & networking' },
  ];

  // Estilos para los logos
  const BoxLogo = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#FFF',
    width: '160px',
    height: '160px',
    boxShadow: '0px 2px 64px rgba(8, 0, 97, 0.08)',
    padding: '16px',
    border: '2px solid #FFF',
    borderRadius: '5px',
    transition: 'all 150ms ease-in',
    '&:hover': {
      border: '2px solid #DB6639',
      boxShadow: '-6px 6px 0px 0px #DB6639',
      marginTop: '-6px',
      marginLeft: '6px',
      marginRight: '-6px',
    },
  };

  // Array con la información de las estadísticas
  const statsMap = [
    { value: 14, label: 'Weeks' },
    { value: '', label: 'Remote Location' },
    { value: 3, label: 'Hours of workload' },
  ];

  return (
    <MKBox display="flex" py={12} position="relative">
      <CustomContainer>
        {/* Contenedor para los logos */}
        <MKBox
          display="flex"
          flexDirection="row"
          gap="16px"
          justifyContent="center"
          flexWrap="wrap"
          style={{ cursor: 'pointer' }}
        >
          {/* Mapeo del array logosMap para generar los logos dinámicamente */}
          {logosMap.map((item) => (
            <MKBox sx={BoxLogo} key={item.name} onClick={() => {}}>
              <MKTypography variant="h6" color="text" mb={1}>
                {item.name}
              </MKTypography>
            </MKBox>
          ))}
        </MKBox>
        {/* Sección de estadísticas */}
        <MKBox py={6} textAlign="center">
          <Grid container spacing={1} pt={12} pb={12} justifyContent="space-around">
            {/* Mapeo del array statsMap para generar las estadísticas dinámicamente */}
            {statsMap.map((item, index) => (
              <Grid
                key={index}
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column' },
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: { xs: '100%', sm: '33%' },
                  mt: { xs: 10, sm: 0 },
                }}
              >
                <MKTypography
                  variant="h1"
                  align="center"
                  fontWeight="bold"
                  sx={{ color: '#DB6639', fontSize: 60 }}
                >
                  {item.label === 'Remote Location' ? <Icon>share_location</Icon> : item.value}
                </MKTypography>
                <MKTypography variant="h5" align="center" fontWeight="bold" sx={{ mt: '20px' }}>
                  {item.label}
                </MKTypography>
              </Grid>
            ))}
          </Grid>
        </MKBox>
      </CustomContainer>
    </MKBox>
  );
}

export default CardDescription;
