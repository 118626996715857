import { Splide, SplideSlide } from '@splidejs/react-splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

// @mui material components

import CustomContainer from '../../../DS/components/Container';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';

// Images

import fishDown from 'assets/images/fish_down.png';
import fishUp from 'assets/images/fish_up.png';
import delia from 'assets/images/team/Delia.png';
import ManuMalvezzi from 'assets/images/team/ManuMalvezzi.jpg';
import blas from 'assets/images/team/blas.png';
import dani from 'assets/images/team/dani.jpg';
import gaston from 'assets/images/team/gaston.png';
import juan from 'assets/images/team/juan.png';
import juand from 'assets/images/team/juand.png';
import maria from 'assets/images/team/maria.png';
import mariano from 'assets/images/team/mariano.jpeg';
import pato from 'assets/images/team/pato.png';
import sofia from 'assets/images/team/sofia.png';

function Team() {
  const data = [
    {
      name: 'Javier (Pato) Cuesta',
      description: 'Operating Partner',
      image: pato,
    },
    {
      name: 'María Castaños',
      description: 'Community Leader',
      image: maria,
    },

    {
      name: 'Mariano Ayerza',
      description: 'BI',
      image: mariano,
    },
    {
      name: 'Juan Durand',
      description: 'Growth Manager',
      image: juand,
    },
    {
      name: 'Delia Aranguren',
      description: 'DPP',
      image: delia,
    },
    {
      name: 'Gaston Murillo',
      description: 'Analyst',
      image: gaston,
    },
    {
      name: 'Juan Oyhanarte',
      description: 'Social Media Manager',
      image: juan,
    },
    {
      name: 'Sofia Castaños',
      description: 'Analista de Relaciones Humanas',
      image: sofia,
    },
    {
      name: 'Blas Camps',
      description: 'Investment Analyst',
      image: blas,
    },
    {
      name: 'Emanuel Malvezzi',
      description: 'Business Analyst',
      image: ManuMalvezzi,
    },
    {
      name: 'Daniel Bravo',
      description: 'Marketing analyst',
      image: dani,
    },
  ];

  const slidesMap = [
    {
      start: 0,
      speed: 1.2,
      images: data,
    },
    {
      start: 1,
      speed: -0.8,
      images: data,
    },
    {
      start: 2,
      speed: 0.3,
      images: data,
    },
    {
      start: 3,
      speed: -0.5,
      images: data,
    },
    {
      start: 4,
      speed: 1,
      images: data,
    },
    {
      speed: -1.3,
      images: data,
    },
  ];

  const SlideStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '16px',
    py: '24px',
    overflow: 'hidden',
    '.splide__track': {
      overflow: 'hidden',
      position: 'relative',
      zIndex: '0',
      padding: '6px',
    },
  };

  const SliderProps = {
    type: 'loop',
    gap: '10px',
    drag: 'free',
    arrows: false,
    pagination: false,
    perPage: 3,
    direction: 'ttb',
    height: '500px',
  };

  const Description = {
    position: 'absolute',
    background: '#07092B',
    bottom: '100%',
    padding: '14px 15px',
    width: '100%',
  };

  const Card = {
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    width: '220px',
    height: '385px',
    opacity: '.6',
    borderRadius: '16px',
    '&:hover': {
      opacity: '1',
      transition: 'all 150ms ease-in',
      boxShadow: '-6px 6px 0px 0px #DB6639',
      marginTop: '-6px',
      marginLeft: '6px',
      marginRight: '-6px',
      '.img-card': {},
      '.description': {
        bottom: '0',
        zIndex: '1',
      },
    },
  };

  const FishDown = {
    position: 'relative',
    '&:after': {
      content: '""',
      backgroundImage: `url(${fishDown})`,
      backgroundRepeat: 'no-repeat',
      display: { xs: 'none', md: 'block' },
      position: 'absolute',
      top: '-24px',
      right: '85%',
      width: '54px',
      height: '136px',
      zIndex: '1',
    },
  };

  const FishUp = {
    position: 'relative',
    '&:after': {
      content: '""',
      backgroundImage: `url(${fishUp})`,
      backgroundRepeat: 'no-repeat',
      display: { xs: 'none', md: 'block' },
      position: 'absolute',
      bottom: '-24px',
      right: '85%',
      width: '54px',
      height: '136px',
      zIndex: '1',
    },
  };

  return (
    <>
      <MKTypography variant="h1" color="text" mb={1} mt={5} style={{ textAlign: 'center' }}>
        <b>Our</b> Team
      </MKTypography>
      <MKBox
        display="flex"
        sx={{
          pt: { xs: 1, sm: 1, md: 6 },
        }}
      >
        <CustomContainer>
          <MKBox
            sx={{
              ...SlideStyle,
            }}
          >
            {slidesMap &&
              slidesMap.map((slidesItem, key) => (
                <MKBox
                  sx={key === 1 ? { ...FishDown } : key === slidesMap.length - 1 && { ...FishUp }}
                >
                  <Splide
                    options={{
                      ...SliderProps,
                      start: slidesItem.start, // Set the starting position for each Splide instance
                      autoScroll: {
                        pauseOnHover: true,
                        pauseOnFocus: true,
                        rewind: false,
                        speed: slidesItem.speed,
                      },
                    }}
                    extensions={{ AutoScroll }}
                  >
                    {slidesItem.images &&
                      slidesItem.images.map((item) => (
                        <SplideSlide>
                          <MKBox
                            sx={{
                              ...Card,
                            }}
                          >
                            <MKBox
                              className="img-card"
                              component="img"
                              src={item.image}
                              alt={item.name}
                              width="100%"
                              position="relative"
                              zIndex={1}
                              top="auto"
                            />
                            <MKBox
                              className="description"
                              sx={{
                                ...Description,
                              }}
                            >
                              <MKTypography variant="teamname" component="p" color="white">
                                {item.name}
                              </MKTypography>
                              <MKTypography variant="teampos" component="p" color="white">
                                {item.description}
                              </MKTypography>
                            </MKBox>
                          </MKBox>
                        </SplideSlide>
                      ))}
                  </Splide>
                </MKBox>
              ))}
          </MKBox>
        </CustomContainer>
      </MKBox>
    </>
  );
}

export default Team;
