/* eslint-disable no-unused-vars */

import PropTypes from 'prop-types';
import { useEffect, useState, useMemo, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';

// Componentes de Material-UI
import { alpha, Button, Icon, useMediaQuery, useTheme } from '@mui/material';

// Componentes personalizados
import DefaultNavbarDropdown from 'DS/complex/Navbars/DefaultNavbar/DefaultNavbarDropdown';
import DefaultNavbarMobile from 'DS/complex/Navbars/DefaultNavbar/DefaultNavbarMobile';
import CustomContainer from 'DS/components/Container';
import MKBox from 'DS/components/MKBox';
import MKButton from 'DS/components/MKButton';

// Imágenes y activos
import iImage from 'assets/images/banners/i-image.png';
import kImage from 'assets/images/banners/k-image.png';
import nImage from 'assets/images/banners/n-image.png';
import oImage from 'assets/images/banners/o-image.png';
import Logo from 'assets/images/logos/logo-koi.svg';
import logoNegro from 'assets/images/logos/logo-negro.svg';

// Iconos
import { ArrowForwardIos } from '@mui/icons-material';

// Funciones auxiliares
import { analyticsTag } from 'helpers/coreHelper';
import { generateWhatsAppURL } from 'helpers/whatsappHelper'; // Importa la función auxiliar

// Constantes
const BRAND_NAME = 'Koi Ventures';

// Datos de contacto para WhatsApp
const CONTACTS = {
  ventures: {
    phone: '+5491167161749', // Blascho
    message: 'Hola, me gustaría obtener más información.',
    label: 'Invest Now',
  },
  build: {
    phone: '+5491163322763', // Gasti
    message: 'Hola, me gustaría obtener más información.',
    label: 'Work with us',
  },
  tech: {
    phone: '+5491163322763', // Gasti
    message: 'Hola, me gustaría obtener más información.',
    label: 'Work with us',
  },
  default: {
    url: 'https://airtable.com/appboYA2hPja4NXFz/pagXXm6eGX0rtIH7o/form',
    label: 'Apply Now',
  },
};

// Rutas para la navegación de escritorio
const HEADER_ROUTES = [
  { name: 'Portfolio', key: 'portfolio', route: '/Portfolio' },
  {
    name: 'Services',
    key: 'services',
    subItems: [
      {
        name: 'Academy',
        description: 'Incubation, acceleration, and growth programs for impact startups.',
        key: 'academy',
        route: '/academy',
        image: kImage,
      },
      {
        name: 'Ventures',
        description:
          'Innovative investment opportunities with specialized mentorship for angel investors.',
        key: 'ventures',
        route: '/ventures',
        image: oImage,
      },
      {
        name: 'Build',
        description: 'Our lab where we build products and services.',
        key: 'build',
        route: '/build',
        image: iImage,
      },
      {
        name: 'Technology',
        description: 'We offer technological solutions to companies and startups.',
        key: 'tech',
        route: '/tech',
        image: nImage,
      },
    ],
  },
  { name: 'Contact Us', key: 'contact-us', route: '/contact-us' },
];

// Rutas para la navegación móvil (sin 'apply-now')
const MOBILE_ROUTES = [
  { name: 'Home', key: 'home', route: '/Home' },
  {
    name: 'Academy',
    description: 'Programas de incubación, aceleración y crecimiento para startups de impacto.',
    key: 'academy',
    route: '/academy',
    image: kImage,
  },
  {
    name: 'Ventures',
    description:
      'Oportunidades de inversión innovadoras con tutorías especializadas para inversores ángeles.',
    key: 'ventures',
    route: '/ventures',
    image: oImage,
  },
  {
    name: 'Build',
    description: 'Construimos nuestros propios productos y servicios.',
    key: 'build',
    route: '/build',
    image: iImage,
  },
  {
    name: 'Technology',
    description: 'Ofrecemos soluciones tecnológicas a empresas.',
    key: 'tech',
    route: '/tech',
    image: nImage,
  },
  // { name: 'Apply Now', key: 'apply-now' }, // Eliminado
];

function DefaultNavbar({ transparent, light, action, sticky, relative, center }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const location = useLocation();
  const { pathname } = location;

  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [scrolled, setScrolled] = useState(true);

  // Alternar el estado del menú móvil
  const toggleMobileNavbar = () => setMobileNavbar((prev) => !prev);

  // Manejar el scroll para actualizar el estado "scrolled"
  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY <= 350);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Estado inicial

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Mapas para estilos según la ruta y el estado "scrolled"
  const navbarStyles = useMemo(() => {
    if (scrolled) {
      switch (pathname) {
        case '/Portfolio':
          return { backgroundColor: alpha('#575DC8', 0.7) };
        case '/privacy-policy':
          return { backgroundColor: 'rgba(87,93,200,0.7)' };
        default:
          return { backgroundColor: 'rgba(255,255,255, 0.05)' };
      }
    } else {
      switch (pathname) {
        case '/Home':
        case '/academy':
        case '/privacy-policy':
          return { backgroundColor: 'rgba(87,93,200,0.7)' };
        case '/ventures':
          return { backgroundColor: alpha('#F0A08C', 0.7) };
        case '/build':
          return { backgroundColor: alpha('#F9DA90', 0.7) };
        case '/tech':
          return { backgroundColor: alpha('#000', 0.7) };
        case '/contact-us':
          return { backgroundColor: '#b0b0b0' };
        case '/portfolio':
          return { backgroundColor: alpha('#575DC8', 0.7) };
        default:
          return { backgroundColor: 'rgba(255,255,255, 0.3)' };
      }
    }
  }, [pathname, scrolled]);

  const textColor = useMemo(() => {
    return pathname === '/build' ? '#000' : '#fff';
  }, [pathname]);

  const logoColor = useMemo(() => {
    return pathname === '/build' ? logoNegro : Logo;
  }, [pathname]);

  // Obtener los datos de contacto según la ruta
  const contactData = useMemo(() => {
    return CONTACTS[pathname.replace('/', '')] || CONTACTS.default;
  }, [pathname]);

  // Función para manejar "Apply Now" / Contacto
  const handleApplyNow = useCallback(() => {
    analyticsTag({ event: `contact_us_header_${contactData.label}` });
    if (contactData.phone) {
      window.open(generateWhatsAppURL(contactData.phone, contactData.message), '_blank');
    } else if (contactData.url) {
      window.open(contactData.url, '_blank');
    }
  }, [contactData]);

  // Definir rutas de navegación móvil
  const extendedMobileRoutes = useMemo(() => MOBILE_ROUTES, [MOBILE_ROUTES]);

  // Renderizar elementos del navbar para escritorio
  const renderNavbarItems = useMemo(
    () =>
      HEADER_ROUTES.map(({ name, subItems, route }) => (
        <DefaultNavbarDropdown
          key={name}
          name={name}
          subItems={subItems}
          route={route}
          light={light}
          navbarBackgroundColor={navbarStyles.backgroundColor}
          textColor={textColor}
        />
      )),
    [light, navbarStyles.backgroundColor, textColor]
  );

  return (
    <CustomContainer
      sx={{
        position: 'fixed',
        top: 0,
        zIndex: 2,
        width: '100%',
      }}
    >
      <MKBox
        zIndex={3}
        position="fixed"
        sx={{
          backgroundColor: navbarStyles.backgroundColor,
          backdropFilter: 'blur(10px)',
          WebkitBackdropFilter: 'blur(10px)', // Compatibilidad con Safari
          mx: 'auto',
          borderRadius: '10px',
          mt: 1,
          p: 2,
          transition: 'all 0.3s ease',
          width: { xs: 'calc(100% - 2rem)', md: 'calc(100% - 3rem)' },
          '&:hover': {
            borderRadius: { xs: '10px', lg: '10px 10px 0 0' },
          },
        }}
      >
        <MKBox display="flex" justifyContent="space-between" alignItems="center">
          {/* Logo */}
          <MKBox
            component={Link}
            to="/Home"
            lineHeight={1}
            py={transparent ? 1.5 : 0.75}
            pl={relative || transparent ? 0 : { xs: 0, lg: 1 }}
            display="flex"
          >
            <MKBox
              component="img"
              src={logoColor}
              alt={BRAND_NAME}
              position="relative"
              zIndex={1}
              sx={{
                height: { xs: '4vh', md: '6vh' },
                maxWidth: '150px',
                minWidth: '50px',
              }}
            />
          </MKBox>

          {/* Elementos del Navbar para Escritorio */}
          {!isMobile && (
            <MKBox display="flex" justifyContent="center" alignItems="center" flexGrow={1} px={2}>
              {renderNavbarItems}
            </MKBox>
          )}

          {/* Botón de Aplicar Ahora para Escritorio */}
          {!isMobile && (
            <Button
              onClick={handleApplyNow}
              endIcon={<ArrowForwardIos />}
              sx={{
                color: '#fff',
                backgroundColor: '#DB6639',
                borderRadius: '10px',
                transition: 'background-color 0.3s ease, color 0.3s ease',
                '&:hover': {
                  backgroundColor: 'transparent',
                  color: '#fff',
                },
                '&:active': {
                  backgroundColor: '#fff',
                  color: '#DB6639',
                },
                '& .MuiButton-endIcon': {
                  borderRadius: '50%',
                  backgroundColor: 'transparent',
                  padding: 0,
                  transition: 'background-color 0.3s ease',
                },
                '& .MuiButton-endIcon, &:active .MuiButton-endIcon': {
                  backgroundColor: '#DB6639',
                  padding: '4px', // Ajusta según tus necesidades
                },
              }}
            >
              {contactData.label}
            </Button>
          )}

          {/* Botón de menú móvil */}
          {isMobile && (
            <Icon
              fontSize="default"
              sx={{
                color: transparent ? '#fff' : '#000',
                cursor: 'pointer',
              }}
              onClick={toggleMobileNavbar}
            >
              {mobileNavbar ? 'close' : 'menu'}
            </Icon>
          )}
        </MKBox>

        {/* Menú Móvil */}
        {isMobile && (
          <DefaultNavbarMobile
            routes={extendedMobileRoutes}
            open={mobileNavbar}
            textColor={textColor}
            toggleNavbar={toggleMobileNavbar}
            applyNow={
              <MKButton
                color="secondary"
                size="large"
                onClick={handleApplyNow}
                sx={{
                  color: '#fff',
                  borderRadius: '10px',
                  ml: 1,
                  mt: 1,
                  width: '100%',
                }}
              >
                {contactData.label}
              </MKButton>
            }
          />
        )}
      </MKBox>
    </CustomContainer>
  );
}

DefaultNavbar.defaultProps = {
  transparent: false,
  light: false,
  action: false,
  sticky: false,
  relative: false,
  center: false,
};

DefaultNavbar.propTypes = {
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(['external', 'internal']).isRequired,
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        'primary',
        'secondary',
        'info',
        'success',
        'warning',
        'error',
        'dark',
        'light',
        'default',
        'white',
      ]),
      label: PropTypes.string.isRequired,
    }),
  ]),
  sticky: PropTypes.bool,
  relative: PropTypes.bool,
  center: PropTypes.bool,
};

export default DefaultNavbar;
