import { useEffect } from 'react';
import { Box } from '@mui/material';

// Routes
import footerRoutes from 'footer.routes';

// Section
import fondoVision from 'assets/images/FondoVision.png';
import DetailedFooter from 'DS/complex/Footers/DetailedFooter';
import Header from 'pages/shared/components/Header';
import Banner from './sections/Banner';
import Program from './sections/Program';
import Process from './sections/Process';
import LineChart from './sections/LineChart';

function BuildLanding() {
  useEffect(() => {
    document.title = 'Koi Build';
  }, []);

  return (
    <Box
      sx={{
        backgroundImage: `url(${fondoVision})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '100%',
      }}
    >
      <Header transparent light relative />
      <Banner />
      <Program />
      <LineChart />
      <Process />
      <DetailedFooter content={footerRoutes} />
    </Box>
  );
}

BuildLanding.getMeta = () => {
  return (
    <>
      <title>{`Build Landing`}</title>
    </>
  );
};

export default BuildLanding;
