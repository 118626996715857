// Material Kit 2 PRO React components
import DetailedFooter from 'DS/complex/Footers/DetailedFooter';
import footerRoutes from 'footer.routes';
import ContactUs from 'pages/home/sections/ContactUs';
import Header from 'pages/shared/components/Header';
import BannerGrowth from '../../academyLanding/sections/Growth/BannerGrowth';
import CardDescription from '../../academyLanding/sections/Growth/CardsDescription';
import FAQ from 'pages/academyLanding/sections/Growth/FAQ';
import AlumniGrowth from 'pages/academyLanding/sections/Growth/AlumniGrowth';

function AcademyLandingGrowth() {
  return (
    <>
      <Header transparent light relative />
      <BannerGrowth />
      <CardDescription />
      <AlumniGrowth />
      {/* <FAQ /> */}
      <ContactUs />
      <DetailedFooter content={footerRoutes} />
    </>
  );
}

export default AcademyLandingGrowth;
