/* eslint-disable no-unused-vars */

import { useContext, useEffect } from 'react';

// react-router components
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { ENVIRONMENT } from 'config/appConfig';

import CssBaseline from '@mui/material/CssBaseline';

// CFOStartup React routes
import routes from 'routes';

// CFOStartup React contexts
import { useUIController } from 'contexts/ui';

import { AuthContext } from 'contexts/auth/auth.context';
import { Auth } from 'vs-core-firebase';

export default function AppWrapper() {
  const [controller, dispatch] = useUIController();
  const { direction, layout } = controller;
  const { pathname } = useLocation();

  const {
    authState: { isAuthenticated, authStatusReported, name, appRols, grants },
    authDispatch,
  } = useContext(AuthContext);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;

    const currentRoute = routes.find((route) => {
      return route.route === pathname;
    });

    if (
      authStatusReported &&
      currentRoute &&
      (currentRoute.targetAppRols || currentRoute.enterpriseRole) &&
      !Auth.userIsGranted({
        userAppRols: appRols,
        userEnterpriseGrants: grants,
        targetAppRols: currentRoute.targetAppRols,
        targetEnterpriseRols: currentRoute.enterpriseRole,
        enterpriseId: null,
      })
    ) {
      window.location = '/forbidden';
    }
  }, [pathname]);

  useEffect(() => {
    // TODO MICHEL
    if (ENVIRONMENT === 'local') {
      setInterval(() => {
        const iframes = document.querySelectorAll('iframe');
        for (let i = 0; i < iframes.length; i++) {
          const iframe = iframes[i];
          if (iframe && (!iframe.id || iframe.id === ''))
            iframes[i].parentNode.removeChild(iframes[i]);
        }
      }, 3000);
    }

    if (authStatusReported) {
      // eslint-disable-next-line no-console
      console.log(layout, appRols, grants);
    }
  }, [authStatusReported, layout]);

  useEffect(() => {
    if (!isAuthenticated) {
      // para que el AuthenticationForm se renderice con la pantalla de registro y no la de login
      authDispatch({
        type: 'SIGNUP',
      });
    }
  }, [isAuthenticated]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        if (route.provider) {
          const Provider = route.provider;
          return (
            <Provider key={route.key}>
              <Route exact path={route.route} component={route.component} key={route.key} />
              {/* <Route exact path={route.route} element={route.element} key={route.key} /> */}
            </Provider>
          );
        }
        return <Route exact path={route.route} component={route.component} key={route.key} />;
        // return <Route exact path={route.route} element={route.element} key={route.key} />;
      }

      return null;
    });

  // Contemploq que no este informado
  // if (!authStatusReported) return <>Loading</>;

  return (
    <>
      <CssBaseline />

      <Switch>
        {getRoutes(routes)}

        <Redirect to="/Home" />
      </Switch>
    </>
  );
}
