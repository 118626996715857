import { useState } from 'react';

// react-router components
import { Link } from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui material components
import Collapse from '@mui/material/Collapse';
import MuiLink from '@mui/material/Link';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';

// Material Kit 2 PRO React examples
import DefaultNavbarDropdown from 'DS/complex/Navbars/DefaultNavbar/DefaultNavbarDropdown';

function DefaultNavbarMobile({ routes, open, toggleNavbar, textColor, applyNow }) {
  const handleSetCollapse = (name) => {
    toggleNavbar();
  };

  const renderNavbarItems = routes.map(({ name, icon, href, route }) => {
    return (
      <DefaultNavbarDropdown
        key={name}
        name={name}
        icon={icon}
        onClick={() => handleSetCollapse(name)}
        href={href}
        route={route}
        textColor={textColor}
        applyNow={applyNow}
      />
    );
  });

  return (
    <Collapse in={Boolean(open)} timeout="auto" unmountOnExit>
      {renderNavbarItems}
    </Collapse>
  );
}

// Typechecking props for the DefaultNavbarMobile
DefaultNavbarMobile.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
};

export default DefaultNavbarMobile;
