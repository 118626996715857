// Import necessary modules
import { useEffect, useState } from 'react';
import { Container, Grid, Box, alpha, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import CustomContainer from 'DS/components/Container';
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';

// Import images
import ventures1 from 'assets/images/banners/venturesBanner/ventures1.png';
import ventures2 from 'assets/images/banners/venturesBanner/ventures2.png';
import ventures3 from 'assets/images/banners/venturesBanner/ventures3.png';
import ventures4 from 'assets/images/banners/venturesBanner/ventures4.png';
import ventures5 from 'assets/images/banners/venturesBanner/ventures5.png';
import ventures6 from 'assets/images/banners/venturesBanner/ventures6.png';
import crownSVG from 'assets/images/illustrations/crown.svg';
import ticketSVG from 'assets/images/illustrations/ticket.svg';
import arrowSVG from 'assets/images/illustrations/arrow.svg';

// Data for SPVs
const spvData = [
  { title: 'SPV 1', date: 'JANUARY ‘23', return: '2.17x', value: 'USD 844k' },
  { title: 'SPV 2', date: 'JUNE ‘23', return: '2x', value: 'USD 767k' },
  { title: 'SPV 3', date: 'DECEMBER ‘23', return: '2x', value: 'USD 774k' },
  { title: 'SPV 4', date: 'JUNE ‘24', return: '2x', value: 'USD 195k' },
];

// Metrics data
const metricsData = [
  { value: 'USD 3k', label: 'Minimum ticket', color: '#5D5DFB', icon: ticketSVG },
  { value: '5x', label: 'Expected return (5/7 years)', color: '#db6639', icon: crownSVG },
  { value: `2 Q's`, label: 'Minimum', color: '#db6639', icon: arrowSVG },
];

function TrackRecord() {
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Handle mouse movement for 3D effect
  const handleMouseMove = (e) => {
    if (isMobile) return; // Disable on mobile devices
    const { left, top, width, height } = e.currentTarget.getBoundingClientRect();
    const x = (e.clientX - left) / width;
    const y = (e.clientY - top) / height;

    const rotationX = (y - 0.5) * 20;
    const rotationY = (x - 0.5) * 20;

    setMousePos({ x: rotationX, y: rotationY });
  };

  // Reset mouse position when leaving the element
  const handleMouseLeave = () => {
    setMousePos({ x: 0, y: 0 });
  };

  return (
    <MKBox display="flex" position="relative" margin={'0px 40px'}>
      <CustomContainer>
        <MKBox pt={6} mt={4} textAlign="start">
          {/* Título */}
          <MKTypography
            variant="h4"
            align="start"
            color="text"
            sx={{
              fontWeight: 400,
              letterSpacing: 1,
              marginBottom: 1,
            }}
          >
            Take a look at our <b>Track Record </b>
          </MKTypography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'space-between',
              mt: 2,
              width: '100%',
              gap: '20px',
            }}
          >
            {/* Left Grid */}
            <Grid
              id="trackRecord"
              container
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
              sx={{
                border: '2px solid #FFE1DA',
                backgroundColor: alpha('#F17D50', 0.1),
                borderRadius: '30px',
                padding: { xs: '40px', md: '70px' },
                gap: { xs: '10px', md: 15 },
                transition: 'transform 0.1s ease-out, box-shadow 0.1s ease-out',
                transform: `perspective(1000px) rotateX(${mousePos.x}deg) rotateY(${mousePos.y}deg)`,
                boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
                '&:hover': {
                  boxShadow: '0px 20px 30px rgba(0, 0, 0, 0.3)',
                },
                width: { xs: '100%', md: '58%' },
                margin: 'auto',
              }}
            >
              {/* Metrics */}
              {[0, 1].map((row) => (
                <Grid
                  item
                  xs={12}
                  key={row}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: { xs: 'column', sm: 'row' },
                    mb: { xs: 2, sm: 0 },
                  }}
                >
                  {[0, 1].map((col) => {
                    const index = row * 2 + col;
                    const data = [
                      { value: '2X', label: 'Average \n return' },
                      { value: '2.3', label: 'USD \n AUM' },
                      { value: '3', label: 'SPV' },
                      { value: '+400k', label: 'Raised \n to date' },
                    ][index];
                    return (
                      <MKBox
                        key={col}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: '20px',
                          mb: { xs: 5, sm: 0 },
                        }}
                      >
                        <MKTypography
                          variant="h2"
                          sx={{ fontWeight: 800, fontSize: { xs: '3rem', md: '4rem' } }}
                        >
                          {data.value}
                        </MKTypography>
                        <MKTypography
                          variant="h5"
                          sx={{ fontWeight: 200, fontSize: { xs: '1.2rem', md: '1.5rem' } }}
                        >
                          {data.label}
                        </MKTypography>
                      </MKBox>
                    );
                  })}
                </Grid>
              ))}
            </Grid>

            {/* Right Grid */}
            <Grid
              container
              spacing={1}
              sx={{
                width: { xs: '100%', md: '38%' },
                margin: 'auto',
              }}
            >
              {spvData.map((spv, index) => (
                <Grid xs={12} sm={6} key={index}>
                  <Box
                    sx={{
                      m: { xs: '20px 0', md: 1 },
                      flexDirection: 'column',
                      display: 'flex',
                      alignItems: 'start',
                      border: '2px solid #F17D50',
                      backgroundColor: alpha('#F17D50', 0.2),
                      justifyContent: 'center',
                      p: '20px',
                      height: '230px',
                      width: '100%',
                      borderRadius: '30px',
                      gap: '20px',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        backgroundColor: alpha('#F17D50', 0.4),
                      },
                    }}
                  >
                    <Box>
                      <MKTypography
                        sx={{
                          fontSize: '1.5rem',
                          fontWeight: 800,
                        }}
                      >
                        {spv.title}
                      </MKTypography>
                      <MKTypography
                        sx={{
                          fontSize: '0.9rem',
                          fontWeight: 200,
                        }}
                      >
                        {spv.date}
                      </MKTypography>
                    </Box>
                    <MKTypography
                      sx={{
                        fontSize: '0.9rem',
                        fontWeight: 300,
                        lineHeight: '1.3rem',
                      }}
                    >
                      {spv.return} {spv.title === 'SPV 4' ? 'Moic' : 'return'} in less than 2 years.
                      {spv.title === 'SPV 4' ? ' Actual' : ' Valuation of'} {spv.value}.
                    </MKTypography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </MKBox>
      </CustomContainer>
    </MKBox>
  );
}

export default TrackRecord;
