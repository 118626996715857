import { useEffect, useState } from 'react';

// Componentes de Material-UI
import { useTheme } from '@emotion/react';
import { Grid } from '@mui/material';

// Componentes personalizados de Material Kit 2 PRO React
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';

// Funciones auxiliares

// Imágenes (si son necesarias en el futuro)
import { Box } from '@mui/material';
import backgroundTech from 'assets/images/banners/banner-tech.png';
import fishUp from 'assets/images/fish_down.png';

// Hook personalizado para manejar la coincidencia de consultas de medios
function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    const listener = () => setMatches(media.matches);
    media.addEventListener('change', listener);
    // Establecer el estado inicial
    setMatches(media.matches);
    return () => media.removeEventListener('change', listener);
  }, [query]);

  return matches;
}

/**
 * Componente de Banner.
 */
function Banner() {
  const theme = useTheme();
  const [showFish, setShowFish] = useState(false);
  const isXs = useMediaQuery('(max-width: 720px)');

  const handleProgramsClick = () => {
    setShowFish(!showFish);
  };

  return (
    // banner tech
    <MKBox
      sx={{
        display: 'grid',
        placeItems: 'center',
        position: 'relative',
        overflow: 'hidden',
        backgroundImage: `url(${backgroundTech})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: '0 0 60px 60px',
        px: { xs: 2, lg: '215px' },
        py: { xs: 18, lg: 27 },
      }}
    >
      {/* Sección de encabezado con descripción */}
      <Grid container spacing={1} sx={{ px: { xs: 1, lg: 0 } }}>
        <Grid item xs={12} lg={12} sx={{ display: 'flex', flexDirection: 'row' }}>
          <Grid item xs={12} lg={12} sx={{ textAlign: 'start' }}>
            <MKTypography
              color="white"
              fontWeight="light"
              sx={{
                mr: 5,
                ml: 5,
                my: '60px',
                fontSize: { xs: '1.5rem', sm: '55px' },
                lineHeight: { xs: '2rem', sm: '90px' }, // Ajustado para mejor legibilidad
                textAlign: 'center',
              }}
            >
              The{' '}
              <Box
                component="span"
                sx={{
                  display: 'inline-block', // Permite transformaciones
                  color: '#000',
                  fontWeight: 'bold',
                  backgroundColor: '#fff',
                  padding: '0px 15px',
                  borderRadius: '10px',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  transform: 'rotate(1deg)', // Rotación ajustada a 10 grados
                  transition: 'all 0.8s ease', // Transición suave
                  zIndex: 10,
                  cursor: 'pointer',
                  '&:hover': {
                    transform: 'rotate(3deg)', // Rotación en hover
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0)',
                  },
                }}
                onClick={handleProgramsClick}
              >
                One Stop
              </Box>{' '}
              shop for
              <br />
              all technology needs.
            </MKTypography>
            <MKTypography
              color="white"
              fontWeight="lighter"
              sx={{ ml: 5, fontSize: '1.5rem', textAlign: 'center' }}
            >
              Simple, scalable and ready to use solutions.
            </MKTypography>
            {showFish && (
              <Box
                component="img"
                src={fishUp}
                alt="Fish Up"
                sx={{
                  position: 'absolute',
                  bottom: '55%',
                  left: '40%',
                  transform: 'translateX(-40%)',
                  width: '30px', // Ajusta el tamaño según sea necesario
                  zIndex: 5, // Para que esté por encima del texto
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </MKBox>
  );
}

export default Banner;
