/* eslint-disable no-console */
import { useEffect } from 'react';

// Routes
import footerRoutes from 'footer.routes';

// Section
import DetailedFooter from 'DS/complex/Footers/DetailedFooter';

import Header from 'pages/shared/components/Header';
import BannerMain from './sections/Banner';

// HelpCenter page components
import { useRouter } from 'helpers/useRouter';

import { analyticsTag } from 'helpers/coreHelper';

import { useState } from 'react';

import { createAirtableRegistry } from 'services/airtableServices';
import CardDescription from './sections/CardsDescription';
import Faq from './sections/Faq';
import Requirements from './sections/Requirements';
import TrackRecord from './sections/TrackRecord';
import WhatWeDo from './sections/WhatWeDo';

import { Box } from '@mui/material';
import fondoVision from 'assets/images/FondoVision.png';

function Landing() {
  const router = useRouter();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    document.title = 'Koi Ventures';
  }, []);

  const handleSubmit = async () => {
    try {
      analyticsTag({
        event: 'purchase',
      });

      const data = { name, email, phoneNumber, searchString: window.location.search };

      const recordsData = {
        records: [
          {
            fields: data,
          },
        ],
      };
      await createAirtableRegistry('Leads', recordsData);

      router.push('/sucesss');
    } catch (e) {
      console.log('error: ', e);
      alert('Ups, an error occur');
    }
  };
  return (
    <Box
      sx={{
        backgroundImage: `url(${fondoVision})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '100%',
      }}
    >
      <Header transparent light relative />
      <BannerMain />
      <WhatWeDo />
      <CardDescription />
      <TrackRecord />
      <Requirements />
      <Faq />
      <DetailedFooter content={footerRoutes} />
    </Box>
  );
}

Landing.getMeta = () => {
  return (
    <>
      <title>{`Ventures Landing`}</title>
    </>
  );
};

export default Landing;
