import { useState } from 'react';

// Componentes de Material-UI
import { useTheme } from '@emotion/react';
import { Box, Button } from '@mui/material';
import { alpha } from '@mui/material/styles';

// Componentes personalizados
import { keyframes } from '@emotion/react';
import CustomContainer from 'DS/components/Container';
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';
import ArrowFoward from 'assets/images/icons/arrow-forward.svg';
import Check from 'assets/images/icons/check.svg';
import ContactFormModal from 'pages/shared/sections/ContactUs';

// Funciones auxiliares
import { analyticsTag } from 'helpers/coreHelper';

/**
 * Genera la URL de WhatsApp.
 * @returns {string} URL de WhatsApp.
 */
const generateWhatsAppURL = () => {
  const phoneNumber = '+5493436442547';
  const message = encodeURIComponent(
    'Hola, los contacto desde la página web de Koi Ventures. Me gustaría trabajar juntos.'
  );
  return `https://wa.me/${phoneNumber}?text=${message}`;
};

const redirectToWhatsApp = () => {
  const url = generateWhatsAppURL();
  setTimeout(() => {
    window.open(url, '_blank');
  }, 1000);
};

const moveArrow = keyframes`
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(20px);
    opacity: 0;
  }
`;

/** Componente de llamada a la acción (CTA).*/
function Cta() {
  const theme = useTheme();

  // Estado para manejar si el botón ha sido clicado
  const [clicked, setClicked] = useState(false);
  const [openContactModal, setOpenContactModal] = useState(false);

  /**
   * Maneja el clic en el botón.
   * Cambia el estado 'clicked' para actualizar el contenido del botón.
   */
  const handleButtonClick = () => {
    setClicked(true);
    setOpenContactModal(true); // Abrir el modal

    // Registrar el evento de análisis
    analyticsTag({ event: 'contact_us_home' });

    // redirigir a WhatsApp
    redirectToWhatsApp();

    // Restablecer el estado después de 2 segundos
    setTimeout(() => {
      setClicked(false);
    }, 2000);
  };

  // Estilos para el botón
  const buttonStyles = {
    borderRadius: '50px',
    mt: { xs: 4, md: 0 },
    backgroundColor: clicked ? theme.palette.primary.main : alpha(theme.palette.primary.main, 0.1),
    pl: '10px',
    transition: 'background-color 0.3s ease, color 0.3s ease',
    '& .button-text': {
      color: clicked ? '#fff' : theme.palette.primary.main,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      '& .button-text': {
        color: '#fff',
      },
      '& .button-icon': {
        color: '#fff',
      },
    },
  };

  return (
    <MKBox
      sx={{
        display: 'grid',
        placeItems: 'center',
        width: '100%',
        height: 'auto',
        my: { xs: 5, md: 10 }, // Margen vertical responsivo
      }}
    >
      <CustomContainer>
        {/* Sección de título */}
        <MKBox pt={8} pb={1} textAlign="center">
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'center',
              alignItems: 'center',
              gap: { xs: 3, md: '180px' }, // Espacio entre elementos ajustado para mobile
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {/* Título principal */}
              <MKTypography
                sx={{
                  fontSize: { xs: '48px', md: '85px' }, // Tamaño de fuente responsivo
                  fontWeight: 'bold',
                }}
                color="text"
                textAlign="center"
              >
                Let's work
              </MKTypography>
              {/* Subtítulo con fondo primario */}
              <Box
                sx={{
                  mt: { xs: 3, md: '35px' }, // Margen superior ajustado
                  p: { xs: 3, md: '35px' }, // Padding ajustado
                  borderRadius: '10px',
                  backgroundColor: theme.palette.primary.main,
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  transition: 'all 0.7s ease',
                  '&:hover': {
                    backgroundColor: alpha(theme.palette.primary.main, 0.8),
                    cursor: 'pointer',
                    boxShadow: 'none',
                  },
                }}
              >
                <MKTypography
                  sx={{
                    fontSize: { xs: '48px', md: '85px' }, // Tamaño de fuente responsivo
                    fontWeight: 'bold',
                    color: '#fff',
                    textShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                  }}
                  textAlign="center"
                >
                  together
                </MKTypography>
              </Box>
            </Box>

            {/* Botón de "Contact us" o "Coooool!" */}
            <Button
              sx={buttonStyles}
              onClick={handleButtonClick}
              aria-label={clicked ? 'Coooool!' : 'Contact us'}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: clicked ? 'row-reverse' : 'row',
                  alignItems: 'center',
                  transition: 'flex-direction 0.3s ease',
                }}
              >
                {/* Contenedor del icono */}
                <Box
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    padding: { xs: '10px', md: '15px' },
                    borderRadius: '50%',
                    width: { xs: '50px', md: '75px' },
                    height: { xs: '50px', md: '75px' },
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    transition: 'background-color 0.3s ease',
                    position: 'relative',
                    overflow: 'hidden',
                  }}
                >
                  {/* Flecha animada */}
                  {!clicked && (
                    <img
                      src={ArrowFoward}
                      alt="arrow"
                      style={{
                        width: { xs: '20px', md: 'auto' },
                        height: { xs: '20px', md: 'auto' },
                      }}
                    />
                  )}

                  {/* Tick animado */}
                  {clicked && (
                    <Box
                      sx={{
                        color: '#fff',
                        fontSize: { xs: '24px', md: '44px' },
                      }}
                    >
                      <img
                        src={Check}
                        alt="check"
                        style={{
                          width: { xs: '20px', md: 'auto' },
                          height: { xs: '20px', md: 'auto' },
                        }}
                      />
                    </Box>
                  )}
                </Box>

                {/* Texto dinámico: "Contact us" o "Coooool!" */}
                <MKTypography
                  className="button-text"
                  variant="button"
                  sx={{
                    color: clicked ? '#fff' : theme.palette.primary.main,
                    ml: clicked ? 3 : 3,
                    mr: clicked ? 3 : 0,
                    fontSize: { xs: '18px', md: '25px' },
                    transition: 'margin 0.5s ease, color 0.3s ease',
                    fontWeight: 'bold',
                  }}
                >
                  {clicked ? 'Coooool!' : 'Contact us'}
                </MKTypography>
              </Box>
            </Button>
          </Box>
        </MKBox>
      </CustomContainer>

      {/* Modal de Contacto
      <ContactFormModal openModal={openContactModal} onClose={() => setOpenContactModal(false)} /> */}
    </MKBox>
  );
}

export default Cta;
