import Header from 'pages/shared/components/Header';

// Section
import BannerBootcamp from '../../academyLanding/sections/Incubation/BannerIncubation';
import Testimonials from '../../academyLanding/sections/Incubation/Testimonials';
import AlumniIncubation from '../../academyLanding/sections/Incubation/AlumniIncubation';
import CardDescription from '../../academyLanding/sections/Incubation/CardsDescription';
import DetailedFooter from 'DS/complex/Footers/DetailedFooter';
import footerRoutes from 'footer.routes';
import Cta from 'pages/home/sections/CallToAction';
import CtaAcademy from 'pages/academyLanding/sections/CallToActionAcademy';
import ContactUs from 'pages/home/sections/ContactUs';

function AcademyLandingIncubation() {
  return (
    <>
      <Header transparent light relative />
      <BannerBootcamp />
      <CardDescription />
      <AlumniIncubation />
      <Testimonials />
      <CtaAcademy />
      <ContactUs />
      <DetailedFooter content={footerRoutes} />
    </>
  );
}

export default AcademyLandingIncubation;
