import React, { useState } from 'react';
import { Modal, Box, Button, TextField, Typography } from '@mui/material';
import MKTypography from 'DS/components/MKTypography';
import { useTheme } from '@emotion/react';
// import emailjs from 'emailjs-com';

// Estilos para el modal y el formulario
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: '#fff',
  borderRadius: '20px', // Bordes curvos
  p: 4,
};

function ContactFormModal({ openModal: isOpenProp, textColor }) {
  const [isOpen, setIsOpen] = useState(isOpenProp ? isOpenProp : false);
  const theme = useTheme();
  const [formData, setFormData] = useState({
    contactName: '',
    email: '',
    message: '',
  });

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // emailjs
    //   .send('service_yourServiceId', 'template_yourTemplateId', formData, 'user_yourUserId')
    //   .then(
    //     (result) => {
    //       alert('Mensaje enviado con éxito');
    //     },
    //     (error) => {
    //       alert('Hubo un error al enviar el mensaje');
    //     }
    //   );
    handleClose();
  };

  return (
    <>
      {/* Botón de "Contact Us" */}
      <MKTypography
        color={'menu'}
        sx={{
          padding: 0,
          color: textColor,
          fontWeight: 'bold',
          display: 'flex',
          alignItems: 'center',
          ml: -3,
          cursor: 'pointer',
        }}
        onClick={handleOpen} // Abre el modal al hacer click
      >
        Contact us
      </MKTypography>

      {/* Modal con el formulario de contacto */}
      <Modal
        open={isOpen}
        onClose={handleClose}
        sx={{
          border: 'none',
        }}
      >
        <Box
          sx={{
            ...modalStyle,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <MKTypography variant="h2" component="h1" sx={{ mb: 2, color: '#5D5DFB' }}>
            Let’s build something together.
          </MKTypography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Nombre"
              name="contactName"
              value={formData.contactName}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Mail"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Mensaje"
              name="message"
              value={formData.message}
              onChange={handleChange}
              fullWidth
              multiline
              rows={5}
              margin="normal"
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{
                mt: 2,
                borderRadius: 2,
                backgroundColor: theme.palette.primary.main,
                color: '#fff',
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark,
                },
              }}
            >
              Enviar formulario
            </Button>
          </form>
        </Box>
      </Modal>
    </>
  );
}

export default ContactFormModal;
