// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import CustomContainer from 'DS/components/Container';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKButton from 'DS/components/MKButton';
import MKTypography from 'DS/components/MKTypography';

// Images
// Acceleration
import DrGea from 'assets/images/logos/dr-gea.png';
import Aconcagua from 'assets/images/logos/logo-aconcagua.png';
import Nippy from 'assets/images/logos/logo-nippy.svg';
import SomosFit from 'assets/images/logos/logo-somosfit.png';
import Maia from 'assets/images/logos/logo-maia.svg';
import Dendro from 'assets/images/logos/logo-dendro.png';
import NoPausa from 'assets/images/logos/logo-no-pausa.png';
import Qxm from 'assets/images/logos/logo-qxm.png';
import enlite from 'assets/images/logos/Enlite (2).png';
import impulsum from 'assets/images/logos/logo-impulsum.png';
import fincrick from 'assets/images/logos/logo-fincrick.png';
import tributo from 'assets/images/logos/logo-tributo.png';
import metamind from 'assets/images/logos/metamind-logo.png';
import goldu from 'assets/images/logos/logo-glodu.png';
import akademi from 'assets/images/logos/logo-akademi.png';
import Vgo from 'assets/images/logos/logo-V-Go.png';
import rezet from 'assets/images/logos/logo-rezet.png';
import duo0 from 'assets/images/logos/duo0-logo.png';
import mentalgram from 'assets/images/logos/logo-mentalgram.svg';
import busencu from 'assets/images/logos/busencu-logo.svg';
import dillo from 'assets/images/logos/dillo-logo.png';

function AlumniBootcamp() {
  const logosMap = [
    {
      image: Aconcagua,
      name: 'Aconcagua',
      url: 'https://aconcagua.finance/',
    },
    {
      image: akademi,
      name: 'Akademi',
      url: 'https://www.akademiapp.com/logosMap.sort((a, b) => a.name.localeCompare(b.name));',
    },
    {
      image: busencu,
      name: 'Busencu',
      url: 'https://busencu.com/',
    },
    {
      image: Dendro,
      name: 'Dendro',
      url: 'https://dendrolatam.com/',
    },
    {
      image: dillo,
      name: 'Dillo',
      url: 'https://dillo.ai/',
    },
    {
      image: DrGea,
      name: 'DrGea',
      url: 'https://drgea.com/',
    },
    {
      image: duo0,
      name: 'Duo0',
      url: 'https://duo0.co/',
    },
    {
      image: enlite,
      name: 'Enlite',
      url: 'https://enlite.health/',
    },
    {
      image: fincrick,
      name: 'Fincrick',
      url: 'https://fincrick.com/',
    },
    {
      image: goldu,
      name: 'Goldu',
      url: 'https://glodu.tech/',
    },
    {
      image: Maia,
      name: 'Maia',
      url: 'https://maia4leaders.splashthat.com/',
    },
    {
      image: mentalgram,
      name: 'Mentalgram',
      url: 'https://mentalgram.infinixsoft.com/',
    },
    {
      image: metamind,
      name: 'Metamind',
      url: 'https://metamind.la/',
    },
    {
      image: NoPausa,
      name: 'NoPausa',
      url: 'https://no-pausa.com/',
    },
    {
      image: Nippy,
      name: 'Nippy',
      url: 'https://nippy.la/',
    },
    {
      image: Qxm,
      name: 'Qxm',
      url: 'https://qxm.com.ar/',
    },
    {
      image: rezet,
      name: 'Rezet',
      url: 'https://www.rezet.global/',
    },
    {
      image: SomosFit,
      name: 'SomosFit',
      url: 'https://somosfit.com/',
    },
    {
      image: tributo,
      name: 'Tributo Simple',
      url: 'https://tributosimple.com/',
    },
    {
      image: Vgo,
      name: 'V-Go',
      url: 'https://www.vgo.com.ar/',
    },
  ];

  const BoxLogo = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#FFF',
    width: '160px',
    height: '160px',
    boxShadow: '0px 2px 64px rgba(8, 0, 97, 0.08)',
    borderRadius: '2px',
    border: '2px solid #FFF',
    transition: 'all 150ms ease-in',
    '&:hover': {
      border: '2px solid #DB6639',
      boxShadow: '-6px 6px 0px 0px #DB6639',
      marginTop: '-6px',
      marginLeft: '6px',
      marginRight: '-6px',
    },
  };

  return (
    <MKBox display="flex">
      <CustomContainer>
        <MKBox pt={2} pb={8} textAlign="center">
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={12} lg={7} alignItems="center" flexDirection="column">
              <MKTypography variant="h1" color="text" mb={1}>
                Alumni
              </MKTypography>
            </Grid>
          </Grid>
        </MKBox>
        <MKBox
          display="flex"
          flexDirection="row"
          gap="16px"
          justifyContent="center"
          flexWrap="wrap"
          style={{ cursor: 'pointer' }}
          mb={6}
        >
          {logosMap &&
            logosMap.map((item) => (
              <MKBox
                sx={{
                  ...BoxLogo,
                }}
                onClick={() => {
                  window.open(item.url);
                }}
              >
                {item.image ? (
                  <MKBox
                    className="img-card"
                    component="img"
                    src={item.image}
                    alt={item.name}
                    width="85px"
                    position="relative"
                    zIndex={1}
                    top="auto"
                  />
                ) : (
                  <MKTypography variant="h4" color="text" mb={1}>
                    {item.name}
                  </MKTypography>
                )}
              </MKBox>
            ))}
        </MKBox>
      </CustomContainer>
    </MKBox>
  );
}

export default AlumniBootcamp;
