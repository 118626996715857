// react-router components
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';
import MuiLink from '@mui/material/Link';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';
import MKButton from 'DS/components/MKButton';

// Icons
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { keyframes } from '@emotion/react';

function StartUpBlogCard({
  image,
  title,
  logo,
  description,
  fact,
  action,
  rotation,
  delay,
  selected,
  onSelect,
}) {
  // const handleCardClick = (e) => {
  //   e.stopPropagation(); // Evitar que el evento se propague al documento
  //   onSelect();
  // };

  // useEffect(() => {
  //   if (!selected) return;

  //   const handleClickOutside = () => {
  //     onSelect();
  //   };

  //   document.addEventListener('click', handleClickOutside);

  //   return () => {
  //     document.removeEventListener('click', handleClickOutside);
  //   };
  // }, [selected, onSelect]);

  const cardActionStyles = {
    display: 'flex',
    alignItems: 'center',
    '& .material-icons, .material-icons-round,': {
      transform: `translateX(2px)`,
      transition: 'transform 2s cubic-bezier(0.34,1.61,0.7,1.3)',
    },
  };

  const moveUpDown = keyframes`
    0% { transform: translateY(0) ${rotation}; }
    50% { transform: translateY(-5px) ${rotation}; }
    100% { transform: translateY(0) ${rotation}; }
  `;

  const BoxLogo = {
    display: 'flex',
    borderRadius: '40px',
    transition: 'all 250ms ease-in',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '454px',
    width: '306px',
    animation: selected ? 'none' : `${moveUpDown} 2s infinite`,
    animationDelay: `${delay}s`,
    userSelect: 'none',
    position: selected ? 'fixed' : 'relative',
    top: selected ? '50%' : 'auto',
    left: selected ? '50%' : 'auto',
    transform: selected ? 'translate(-50%, -50%)' : rotation,
    zIndex: selected ? 1000 : 'auto',
    cursor: 'pointer',
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  return (
    <Card sx={{ ...BoxLogo }}>
      <MKBox p={3} display="flex" flexDirection="column" justifyContent="space-between" flex="1">
        <MKBox
          pt="3"
          height="5rem"
          sx={{
            userSelect: 'none',
          }}
        >
          <MKBox
            className="img-card"
            component="img"
            src={logo}
            alt={title}
            width="120px"
            position="relative"
            zIndex={1}
            top="auto"
          />
        </MKBox>
        <MKBox py={3} flex="1" style={{ alignSelf: 'flex-start', userSelect: 'none' }}>
          <MKTypography
            variant={selected ? 'h4' : 'body2'}
            component="p"
            color={title === 'No Pausa' ? 'light' : 'dark'}
            sx={{ fontWeight: selected ? 'bold' : 'regular' }}
          >
            {description}
          </MKTypography>
        </MKBox>
        {action.type === 'external' ? (
          <MKBox display="flex" justifyContent="space-between">
            <MKTypography
              component={MuiLink}
              href={action.route}
              target="_blank"
              rel="noreferrer"
              variant="body2"
              fontWeight="regular"
              color={title === 'No Pausa' ? 'light' : 'dark'}
              sx={cardActionStyles}
            >
              {action.label}
              <Icon sx={{ fontWeight: 'bold' }}>arrow_forward</Icon>
            </MKTypography>
            <MKBox display="flex" justifyContent="space-around" minWidth="40%">
              <MKTypography
                component={MuiLink}
                href={action.lnRoute}
                target="_blank"
                rel="noreferrer"
                variant="body1"
                fontWeight="regular"
                color={title === 'No Pausa' ? 'light' : 'dark'}
                sx={cardActionStyles}
              >
                {action.lnRoute ? <LinkedInIcon fontSize="medium" /> : null}
              </MKTypography>
              <MKTypography
                component={MuiLink}
                href={action.twRoute}
                target="_blank"
                rel="noreferrer"
                variant="body2"
                fontWeight="regular"
                color={title === 'No Pausa' ? 'light' : 'dark'}
                sx={cardActionStyles}
              >
                {action.twRoute ? <TwitterIcon fontSize="medium" /> : null}
              </MKTypography>
              <MKTypography
                component={MuiLink}
                href={action.igRoute}
                target="_blank"
                rel="noreferrer"
                variant="body2"
                fontWeight="regular"
                color={title === 'No Pausa' ? 'light' : 'dark'}
                sx={cardActionStyles}
              >
                {action.igRoute ? <InstagramIcon fontSize="medium" /> : null}
              </MKTypography>
            </MKBox>
          </MKBox>
        ) : (
          <MKTypography
            component={Link}
            to={action.route}
            variant="body2"
            fontWeight="regular"
            color={'dark'}
            sx={cardActionStyles}
          >
            {action.label}
            <Icon sx={{ fontWeight: 'bold' }}>arrow_forward</Icon>
          </MKTypography>
        )}
      </MKBox>
    </Card>
  );
}

// Actualiza las propTypes para incluir 'selected' y 'onSelect'
StartUpBlogCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired, // Añadido si falta
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(['external', 'internal']).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      'primary',
      'secondary',
      'info',
      'success',
      'warning',
      'error',
      'dark',
      'light',
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
  rotation: PropTypes.string, // Añadido si falta
  delay: PropTypes.number, // Añadido si falta
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default StartUpBlogCard;
