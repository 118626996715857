// Importaciones necesarias
import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// Componentes de Material-UI y personalizados
import CustomContainer from 'DS/components/Container';
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';

// Imágenes
import ventures1 from 'assets/images/banners/venturesBanner/ventures1.png';
import ventures2 from 'assets/images/banners/venturesBanner/ventures2.png';
import ventures3 from 'assets/images/banners/venturesBanner/ventures3.png';
import ventures4 from 'assets/images/banners/venturesBanner/ventures4.png';
import ventures5 from 'assets/images/banners/venturesBanner/ventures5.png';
import ventures6 from 'assets/images/banners/venturesBanner/ventures6.png';

function CardDescription() {
  const logosMap = [
    {
      name: 'Double selection process',
      background: ventures6,
    },
    {
      name: 'Exhaustive due diligence',
      background: ventures5,
    },
    {
      name: 'Positive impact',
      background: ventures4,
    },
    {
      name: 'Diversified',
      background: ventures3,
    },
    {
      name: 'Investment and Startup tracking platform',
      background: ventures2,
    },
    {
      name: 'High expected returns in a shorter time',
      background: ventures1,
    },
  ];

  // Definimos los estilos de BoxLogo
  const BoxLogo = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: { xs: '100%', md: '15%' }, // Ancho completo en móvil, 200px en escritorio
    height: '200px',
    boxShadow: '0px 2px 64px rgba(8, 0, 97, 0.08)',
    borderRadius: '10px',
    padding: '16px',
    border: '1px solid #FFF',
    transition: 'all 150ms ease-in',
    '&:hover': {
      boxShadow: '0px 2px 64px rgba(8, 0, 97, 0.3)',
      transform: 'scale(1.05)',
    },
  };

  return (
    <MKBox display="flex" position="relative" padding={2} margin={'10px 40px'}>
      <CustomContainer>
        {/* Título */}
        <MKTypography
          variant="h4"
          align="start"
          color="text"
          sx={{
            fontWeight: 400,
            letterSpacing: 1,
            marginBottom: 4,
          }}
        >
          <b>How </b>we do it
        </MKTypography>

        {/* Contenedor de Logos */}
        <MKBox
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }} // Columnas en móvil, fila en escritorio
          gap="16px"
          justifyContent={{ xs: 'center', md: 'space-between' }}
          alignItems="center"
          flexWrap="wrap"
          py={5}
        >
          {logosMap.map((item, index) => (
            <MKBox
              key={index}
              sx={{
                ...BoxLogo,
                backgroundImage: `url(${item.background})`,
              }}
            >
              <MKTypography
                variant="h6"
                color="text"
                mb={1}
                sx={{ fontSize: { xs: '1rem', md: 'inherit' } }} // Ajuste de tamaño en móvil
              >
                {item.name}
              </MKTypography>
            </MKBox>
          ))}
        </MKBox>

        {/* Texto Descriptivo */}
        <MKTypography
          color="text"
          variant="h5"
          sx={{
            fontWeight: 300,
            textAlign: 'center',
            lineHeight: { xs: '1.5rem', sm: '3rem' },
            fontSize: { xs: '1rem', md: 'inherit' }, // Ajuste de tamaño en móvil
            px: { xs: 1, md: 0 }, // Padding horizontal en móvil
          }}
        >
          Our rolling fund is meticulously designed to create{' '}
          <span style={{ color: '#F17D50', fontWeight: 'bold' }}>positive change</span> in the
          ecosystem while providing investors with the potential for substantial{' '}
          <span style={{ color: '#F17D50', fontWeight: 'bold' }}>financial growth</span>. By
          focusing on both social responsibility and robust financial performance, we ensure that
          your investment not only yields impressive returns but also contributes to{' '}
          <span style={{ color: '#F17D50', fontWeight: 'bold' }}>
            meaningful and lasting improvements
          </span>{' '}
          in the communities and industries we support.
        </MKTypography>
      </CustomContainer>
    </MKBox>
  );
}

export default CardDescription;
