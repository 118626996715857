import { useRef } from 'react';

// SwiperJS
import SwiperCore, { Autoplay, Navigation } from 'swiper';

// SwiperJS react components
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';

// SwiperJS styles
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/swiper.min.css';

import Grid from '@mui/material/Grid';
import CustomContainer from '../../../../DS/components/Container';

// Material Kit 2 PRO React components
import TestimonialCard from 'DS/complex/Cards/InfoCards/TestimonialCard';
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';

// Logos
import { useRouter } from 'helpers/useRouter';

function Testimonials() {
  const router = useRouter();

  // install SwiperJS modules
  SwiperCore.use([Autoplay, Navigation]);

  // Swiper navigation buttons styles
  const navigationStyles = {
    position: 'absolute',
    top: 0,
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100px',
    height: '100%',
    textAlign: 'center',
    opacity: 1,
    cursor: 'pointer',
    transition: 'opacity 0.15s ease',
    '&:hover, &:focus': {
      opacity: 1,
    },
    '&.swiper-button-disabled img': {
      width: '42px',
      opacity: 0.5,
    },
  };

  const CardsStyles = {
    overflow: 'hidden',
    width: '440px',
    height: 'auto',
    padding: '15px',
    display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
  };

  return (
    <MKBox
      sx={{
        pb: { xs: 1, sm: 1, md: 5 },
      }}
      position="relative"
      alignItems="center"
    >
      <CustomContainer>
        <MKBox pb={5}>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={12}>
              <MKTypography variant="flywheel" color="primary" sx={{ fontWeight: 300 }}>
                · Testimonials
              </MKTypography>
            </Grid>
            <Grid item xs={12} lg={12} a flexDirection="column">
              <MKTypography variant="h4" color="text">
                <b>What</b> they say
              </MKTypography>
            </Grid>
          </Grid>
        </MKBox>
        <MKBox>
          <Swiper slidesPerView={'auto'} spaceBetween={5}>
            {/* <SwiperSlide
              style={{
                ...CardsStyles,
              }}
            >
              <TestimonialCard
                title="Constanza Naso"
                subtitle="CEO & Co Founder @ MAIA"
                quote="A space to grow shoulder to shoulder with incredible people with a lot of experience and generosity, undoubtedly highly recommended."
                direction="center"
                color="primary"
                img={
                  'https://media.licdn.com/dms/image/D4D03AQE4ln1SA2wuJw/profile-displayphoto-shrink_100_100/0/1699708861619?e=1717632000&v=beta&t=ClwRidQ9j1cLeXFZ0rl4820c5G2VVMHLyI41e6Bj3ao'
                }
                onClick={() => {
                  window.open(
                    'https://www.linkedin.com/in/constanza-naso-66248462?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAAA09dqoBEtpqQB8fzOBSIXue7CCDEFl67AA&lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3BEQWN8tZsSreU%2BT7wG9r5Vg%3D%3D'
                  );
                }}
              />
            </SwiperSlide> */}
            <SwiperSlide
              style={{
                ...CardsStyles,
              }}
            >
              <TestimonialCard
                title="Ezequiel Canle Santamaría"
                subtitle="CEO & Co Founder @ Acuerdo Sencillo"
                quote="Going through this process hand in hand with Koi was fundamental."
                img={
                  'https://media.licdn.com/dms/image/D4D03AQEPKdyoobz3qA/profile-displayphoto-shrink_100_100/0/1700347245039?e=1717632000&v=beta&t=lRDnPvHfZMhqTyyWyzwMEgz_Kx4yp7MDqHvuQMvJDS4'
                }
                direction="center"
                color="secondary"
                onClick={() => {
                  window.open(
                    'https://www.linkedin.com/in/ecsantamaria?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAAANWg2EBEEnjLFBnnzif5LCAe7cRHHAablY&lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3BG%2F%2FF1NcOQ5CyKCgzu9KeMw%3D%3D'
                  );
                }}
              />
            </SwiperSlide>

            <SwiperSlide
              style={{
                ...CardsStyles,
              }}
            >
              <TestimonialCard
                title="Martín Lipovetzky"
                subtitle="CEO & Co Founder @ EIOU"
                quote="I entered KOI seeking support and found a community of incredible entrepreneurs who accompany me and foster mutual growth."
                img={
                  'https://media.licdn.com/dms/image/D4D03AQFFcENWS3AOtQ/profile-displayphoto-shrink_100_100/0/1697225690028?e=1717632000&v=beta&t=_YEC9k06C9oUuObRQ2iSfXogDs4PbCqMAqJLe6eFO4c'
                }
                direction="center"
                color="black"
                onClick={() => {
                  window.open(
                    'https://www.linkedin.com/in/martin-lipovetzky-b23b5823b?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAADvLBUsBOYft0xcwKGpHYMftOGJuKeaMPhw&lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3BzQ9jPNNjQdOXUjxPARA9gQ%3D%3D'
                  );
                }}
              />
            </SwiperSlide>

            <SwiperSlide
              style={{
                ...CardsStyles,
              }}
            >
              <TestimonialCard
                title="Mariana Sá"
                subtitle="CEO & Co Founder @ Fincrick"
                quote=" I feel like KOI is another founder pushing for things to move forward in my startup."
                img={
                  'https://media.licdn.com/dms/image/C4E03AQH9scwNhGKJnw/profile-displayphoto-shrink_400_400/0/1657846032353?e=1717632000&v=beta&t=pjV_aJ2jLWqkY11eW7t72XpyDMsdRLIHxtQMosnNMhM'
                }
                direction="center"
                color="black"
                onClick={() => {
                  window.open(
                    'https://www.linkedin.com/in/mariana-almeida-s%C3%A1-a4b618bb?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAABmKxNsBTO474N7mketRQ1-U3yW5lZN2u4E&lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3BKaAHhpe2Tk%2BMlDhstXNAmQ%3D%3D'
                  );
                }}
              />
            </SwiperSlide>
          </Swiper>
        </MKBox>
      </CustomContainer>
    </MKBox>
  );
}

export default Testimonials;
