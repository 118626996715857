import { useRef, useState } from 'react';
import theme from 'assets/theme';

// Componentes de Material-UI
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';

// Componentes personalizados de Material Kit 2 PRO React
import StartUpBlogCard from 'DS/complex/Cards/BlogCards/StartUpBlogCard';
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';

// Funciones auxiliares
import { analyticsTag } from 'helpers/coreHelper';

// Importaciones de imágenes de logos
import revil from 'assets/images/logos/reliv.png';
import Enlite from 'assets/images/logos/enlite.png';
import Nippy from 'assets/images/logos/nippy.png';
import NoPausa from 'assets/images/logos/noPausa.png';
import Tributo from 'assets/images/logos/tributo.png';

// Importaciones de imágenes de fondos para las tarjetas
import fondoReliv from 'assets/images/backgroundCards/fondoReliv.png';
import fondoNippy from 'assets/images/backgroundCards/fondoNippy.png';
import fondoEnlite from 'assets/images/backgroundCards/fondoEnlite.png';
import fondoTributo from 'assets/images/backgroundCards/fondoTributo.png';
import fondoNoPausa from 'assets/images/backgroundCards/fondoPausa.png';

/**
 * Lista de compañías con sus detalles.
 */
const companies = [
  {
    image: fondoReliv,
    title: 'Reliv',
    logo: revil,
    description:
      'The first digital medical ecosystem in Ecuador, with the aim of putting the patient at the center of attention and making the healthcare process simpler and more agile.',
    delay: 1,
    rotation: 'rotate(8deg)',
    action: {
      type: 'external',
      route: 'https://reliv.la',
      label: 'Ir al sitio',
      color: 'primary',
      lnRoute:
        'https://www.linkedin.com/company/reliv-healthcare/?lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3B2rFZ6jxdTLmf0pRPNbi9Ag%3D%3D',
    },
  },
  {
    image: fondoNippy,
    title: 'Nippy',
    logo: Nippy,
    description:
      'Nippy connects the (under-served) gig economy with services, benefits and opportunities.',
    delay: 2,
    rotation: 'rotate(-6deg)',
    action: {
      type: 'external',
      route: 'https://nippy.la/',
      label: 'Ir al sitio',
      color: 'primary',
      lnRoute: 'https://www.linkedin.com/company/nippy-latam/',
      twRoute: 'https://twitter.com/NippyLatam',
    },
  },
  {
    image: fondoEnlite,
    title: 'Enlite',
    logo: Enlite,
    description:
      'Enlite is a platform that optimizes access to a network of therapeutic companions, caregivers, and sociotherapeutic operators.',
    delay: 1,
    rotation: 'rotate(9deg)',
    action: {
      type: 'external',
      route: 'https://riat.app/',
      label: 'Ir al sitio',
      color: 'primary',
      lnRoute: 'https://www.linkedin.com/company/riatlimited',
      igRoute: 'https://www.instagram.com/riatbrasil/',
    },
  },
  {
    image: fondoTributo,
    title: 'Tributo Simple',
    logo: Tributo,
    description:
      'Tributo Simple is the first online accountant designed to help independent professionals and small businesses in Latin America.',
    delay: 3,
    rotation: 'rotate(-5deg)',
    action: {
      type: 'external',
      route: 'https://tributosimple.com/',
      label: 'Ir al sitio',
      color: 'primary',
      lnRoute: 'https://www.linkedin.com/company/tributosimple/',
    },
  },
  {
    image: fondoNoPausa,
    title: 'No Pausa',
    logo: NoPausa,
    description:
      'NoPausa is a community that promotes open dialogue about menopause in addition to Menocheck: its self-assessment and treatment tool for women.',
    delay: 2,
    rotation: 'rotate(8deg)',
    action: {
      type: 'external',
      route: 'https://menocheck.no-pausa.com/',
      label: 'Ir al sitio',
      color: 'primary',
      lnRoute: 'https://www.linkedin.com/company/no-pausa-menopausia/',
      igRoute: 'https://www.instagram.com/nopausaig/',
    },
  },
];

/**
 * Componente principal que muestra las tarjetas de las compañías.
 */
function Companies() {
  // Estado para manejar si se está arrastrando para hacer scroll
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const scrollRef = useRef(null);
  const [selectedCard, setSelectedCard] = useState(null);

  /**
   * Maneja la selección de una tarjeta.
   * Solo permite una tarjeta seleccionada a la vez y solo en escritorio.
   * @param {number} index - Índice de la tarjeta seleccionada.
   */
  const handleCardSelect = (index) => {
    const isDesktop = window.innerWidth >= 1024; // Define el ancho mínimo para escritorio
    if (!isDesktop) return; // Si no es escritorio, no hace nada

    // Si la tarjeta ya está seleccionada, la deselecciona; de lo contrario, selecciona la nueva
    setSelectedCard((prevSelected) => (prevSelected === index ? null : index));
  };

  /**
   * Maneja el evento de presionar el mouse para iniciar el arrastre.
   * @param {object} e - Evento del mouse.
   */
  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - scrollRef.current.offsetLeft);
    setScrollLeft(scrollRef.current.scrollLeft);
  };

  /**
   * Maneja el evento de mover el mouse para realizar el scroll.
   * @param {object} e - Evento del mouse.
   */
  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Velocidad de desplazamiento
    scrollRef.current.scrollLeft = scrollLeft - walk;
  };

  /**
   * Maneja el evento de soltar el mouse para finalizar el arrastre.
   */
  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <MKBox
      sx={{
        display: 'grid',
        placeItems: 'center',
        width: '100%',
        height: 'auto',
        my: 5, // Margen vertical
      }}
    >
      {/* Sección de título */}
      <MKBox pt={8} pb={1} textAlign="center">
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12} lg={12} alignItems="center" flexDirection="column">
            <MKTypography variant="h1" color="text" mb={3}>
              Only some of our <b>Highlights</b>
            </MKTypography>
          </Grid>
        </Grid>
      </MKBox>

      {/* Contenedor con desplazamiento horizontal y funcionalidad de arrastre */}
      <MKBox
        ref={scrollRef}
        sx={{
          display: 'flex',
          flexDirection: 'row', // Disposición horizontal de las tarjetas
          overflowX: 'auto', // Habilita el scroll horizontal
          width: '100%',
          px: 3, // Padding horizontal
          cursor: isDragging ? 'grabbing' : 'grab', // Cambia el cursor según el estado de arrastre
          '&::-webkit-scrollbar': {
            display: 'none', // Oculta la barra de scroll en navegadores WebKit
          },
        }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        {companies.map((company, idx) => (
          <MKBox
            key={idx}
            sx={{
              height: '500px',
              display: 'flex',
              alignItems: 'center',
              marginRight: '16px', // Espacio entre tarjetas
            }}
          >
            <StartUpBlogCard
              {...company}
              selected={selectedCard === idx} // Propiedad para indicar si está seleccionada
              onSelect={() => handleCardSelect(idx)} // Función para manejar la selección
            />
          </MKBox>
        ))}
      </MKBox>

      {/* Botón para ver todas las tarjetas */}
      <MKBox
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          my: 3, // Margen vertical
        }}
      >
        <Button
          onClick={() => {
            analyticsTag({
              event: 'view_portfolio',
            });
            window.location.href = '/portfolio'; // Redirige al usuario
          }}
          sx={{
            color: '#fff',
            backgroundColor: theme.palette.primary.main,
            borderRadius: '8px',
            padding: '10px 20px',
            '&:hover': {
              backgroundColor: theme.palette.primary.dark,
              color: '#fff',
            },
          }}
        >
          See all
        </Button>
      </MKBox>
    </MKBox>
  );
}

export default Companies;
