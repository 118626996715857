// @mui material components
import { Icon } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';

import CustomContainer from 'DS/components/Container';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKButton from 'DS/components/MKButton';
import MKTypography from 'DS/components/MKTypography';

function useNumberCounter(end, duration = 2000, incrementTime = 100) {
  const [number, setNumber] = useState(0);

  useEffect(() => {
    let start = 0;
    const step = (end - start) / (duration / incrementTime);
    const interval = setInterval(() => {
      start += step;
      if (start >= end) {
        start = end;
        clearInterval(interval);
      }
      setNumber(Math.round(start));
    }, incrementTime);

    return () => clearInterval(interval);
  }, [end, duration, incrementTime]);

  return number;
}

function CardDescription() {
  const incubationPerks = [
    {
      name: 'Weeks',
      end: 4,
      color: '#DB6639',
    },
    {
      name: 'Remote Location',
      end: 0,
      color: '#DB6639',
    },
    {
      name: 'Hours of workload',
      end: 8,
      color: '#DB6639',
    },
  ];
  const incubationItems = [
    {
      name: '1:1 guidance with Koi Alumni',
    },
    {
      name: 'Personalized mentoring with Koi team members',
    },
    {
      name: 'Workshops: OKRs; P&L; Legal; Finance; PMF',
    },
    {
      name: 'Fireside chats with Experts',
    },
  ];

  const BoxLogo = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#FFF',
    width: '160px',
    height: '160px',
    boxShadow: '0px 2px 64px rgba(8, 0, 97, 0.08)',
    padding: '16px',
    border: '2px solid #FFF',
    transition: 'all 150ms ease-in',
    borderRadius: '5px',
    '&:hover': {
      border: '2px solid #DB6639',
      boxShadow: '-6px 6px 0px 0px #DB6639',
      marginTop: '-6px',
      marginLeft: '6px',
      marginRight: '-6px',
    },
  };
  return (
    <MKBox display="flex" my={10} position="relative">
      <CustomContainer>
        <MKBox
          display="flex"
          flexDirection="row"
          gap="16px"
          justifyContent="center"
          flexWrap="wrap"
        >
          {incubationItems &&
            incubationItems.map((item) => (
              <MKBox
                sx={{
                  ...BoxLogo,
                }}
                onClick={() => {}}
              >
                <MKTypography variant="h6" color="text" mb={1}>
                  {item.name}
                </MKTypography>
              </MKBox>
            ))}
        </MKBox>
        <MKBox pt={6} pb={0} textAlign="center">
          <Grid container spacing={1} justifyContent="space-around">
            {incubationPerks.map((item, index) => {
              const count = useNumberCounter(
                typeof item.end === 'number' ? item.end : parseFloat(item.end)
              );
              return (
                <Grid
                  key={index}
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column' },
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: { xs: '100%', sm: '33%' },
                    mt: 10,
                  }}
                >
                  <Box
                    sx={{
                      width: 'fit-content',
                      padding: '20px 30px',
                      borderRadius: '20px',
                      transform: 'all 150ms ease-in',
                    }}
                  >
                    <MKTypography
                      variant="h1"
                      align="center"
                      fontWeight="bold"
                      sx={{ color: item.color, fontSize: 60 }}
                    >
                      {item.name === 'Remote Location' ? <Icon>share_location</Icon> : count}
                    </MKTypography>
                    <MKTypography
                      variant="h5"
                      align="center"
                      fontWeight="bold"
                      sx={{ mt: '20px', color: '#000' }}
                    >
                      {item.name}
                    </MKTypography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </MKBox>
      </CustomContainer>
    </MKBox>
  );
}

export default CardDescription;
