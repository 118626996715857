import { useEffect } from 'react';

// Routes
import footerRoutes from 'footer.routes';

// Section
import fondoVision from 'assets/images/FondoVision.png';
import DetailedFooter from 'DS/complex/Footers/DetailedFooter';
import Header from 'pages/shared/components/Header';
import Banner from './sections/Banner';
import InfoBanner from './sections/Info';
import Program from './sections/Program';

import { Box } from '@mui/material';
import Portfolio from './sections/Portfolio';

function TechLanding() {
  useEffect(() => {
    document.title = 'Koi Tech';
  }, []);

  return (
    <Box
      sx={{
        backgroundImage: `url(${fondoVision})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '100%',
      }}
    >
      <Header transparent light relative />
      <Banner />
      <Program />
      <InfoBanner />
      <Portfolio />
      <DetailedFooter content={footerRoutes} />
    </Box>
  );
}

TechLanding.getMeta = () => {
  return (
    <>
      <title>{`Tech Landing`}</title>
    </>
  );
};

export default TechLanding;
