// src/components/withLazyLoad.js

import React, { Suspense, lazy } from 'react';

const withLazyLoad = (importFunc, fallback = null) => {
  const LazyComponent = lazy(importFunc);

  const LazyLoadWrapper = (props) => (
    <Suspense fallback={fallback}>
      <LazyComponent {...props} />
    </Suspense>
  );

  return LazyLoadWrapper;
};

export default withLazyLoad;
