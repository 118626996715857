// First, ensure you have '@mui/material' installed to use these components
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import CustomContainer from 'DS/components/Container';
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';

// Images Avatars
const faqs = [
  {
    question: 'How do we define impact?',
    answer:
      'Koi works across three verticals: Personal impact or wellness, social impact, and environmental impact',
  },
  {
    question: 'What does the program include?',
    answer:
      'The program includes: mentoring sessions 1:1 with internal and external mentors, workshops on specific topics led by specialists, talks with experts, personal development, fundraising, and investment pitch.',
  },
  {
    question: 'How many startups are per program?',
    answer:
      'We have a maximum of 6 startups per program, to ensure we can provide the best support and attention to each startup.',
  },
  {
    question: 'How is the post program support?',
    answer:
      'Our purpose is to keep supporting the startups after the program, we have a network of mentors, investors, and partners that can help the startups to keep growing. That is why we make a mensual follow-up with the startups.',
  },
  {
    question: 'Does it have any cost?',
    answer:
      'Just to be aligned with your objectives we use the advisory shares model in our programs pluss a small fee to cover the operational costs that varies from plan to plan.',
  },

  // Add other FAQs in the same format
];
function FAQ({}) {
  const isXs = useMediaQuery('(max-width: 720px)');

  return (
    <>
      <MKBox
        width="100%"
        sx={{
          display: 'grid',
          placeItems: 'center',
          mt: 5,
        }}
      >
        <CustomContainer sx={{ maxWidth: '1620px!important' }} maxWidth={false}>
          <Grid
            item
            xs={12}
            lg={12}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              px: { xs: 0, lg: 2 },
              justifyContent: 'center',
              mt: { xs: '20px', sm: '60px' },
            }}
          >
            <Grid item xs={12} lg={12} sx={{ textAlign: 'left' }}>
              <MKTypography variant="flywheel" color="primary" sx={{ fontWeight: 300 }}>
                · HELP
              </MKTypography>
              <MKTypography variant="h4" color="text" fontWeight="bold">
                FAQ
              </MKTypography>
            </Grid>
          </Grid>

          {faqs.map((faq, index) => (
            <Accordion
              key={index}
              sx={{
                width: '100%',
                mt: 2,
                border: 'none',
                backgroundColor: '#FFFEF8',
                boxShadow: 'none',
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <MKTypography variant="h6" color="text" fontWeight="bold">
                  {faq.question}
                </MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography variant="text" color="text">
                  {faq.answer}
                </MKTypography>
              </AccordionDetails>
            </Accordion>
          ))}
        </CustomContainer>
      </MKBox>
    </>
  );
}

export default FAQ;
