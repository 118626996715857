import React from 'react';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material';

import { useInView } from 'react-intersection-observer';

import CustomContainer from 'DS/components/Container';
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';
import TechAi from 'assets/images/banners/TechAi.png';
import TechBigdata from 'assets/images/banners/TechBigdata.png';
import TechIntegration from 'assets/images/banners/TechIntegration.png';
import TechMobile from 'assets/images/banners/TechMobile.png';
import TechWeb from 'assets/images/banners/TechWeb.png';

const listadoTech = [
  {
    id: 1,
    title: 'AI',
    subtitle: 'Embrace AI to enhance efficiency and unlock new business opportunities.',
    image: TechAi,
  },
  {
    id: 2,
    title: 'Web Apps',
    subtitle: 'Simple and scalable platforms to tackle diverse challenges.',
    image: TechWeb,
  },
  {
    id: 3,
    title: 'Mobile Apps',
    subtitle: 'Mobile apps with enhanced user experiences.',
    image: TechMobile,
  },
  {
    id: 4,
    title: 'Integration Solutions',
    subtitle: 'Integration between CRM, ERPs and BaaS solutions.',
    image: TechIntegration,
  },
  {
    id: 5,
    title: 'BI & Data',
    subtitle:
      'Meet our Business intelligence team, unlocking the power of data to revolutionize your company’s journey towards becoming a data-driven enterprise.',
    image: TechBigdata,
  },
];

const InfoBanner = () => {
  const theme = useTheme();

  return (
    <MKBox
      py={{
        xs: 5,
        sm: 10,
      }}
      width="100%"
      sx={{ display: 'grid', placeItems: 'center' }}
      px={{ xs: 2, lg: 30 }}
    >
      <CustomContainer sx={{ maxWidth: '1620px!important' }} maxWidth={false}>
        {listadoTech.map((item, index) => {
          const { ref, inView } = useInView({
            threshold: 0.3, // Cambia este valor según la sensibilidad que quieras
            triggerOnce: true, // Solo ejecutar la animación una vez
          });

          return (
            <Grid
              key={item.id}
              ref={ref}
              container
              alignItems="center"
              sx={{
                mb: 25,
                minHeight: '300px',
                transform: inView ? 'translateY(0)' : 'translateY(50px)',
                opacity: inView ? 1 : 0,
                transition: 'all 0.6s ease-out',
              }}
            >
              {/* Componente de Texto */}
              <Grid
                item
                xs={12}
                md={6}
                order={{
                  xs: 1,
                  md: index % 2 === 0 ? 1 : 2,
                }}
              >
                <MKBox
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: '100%',
                    textAlign: { xs: 'center', md: index % 2 === 0 ? 'left' : 'right' },
                    position: 'relative',
                  }}
                >
                  {/* Número de id en el fondo */}
                  <MKTypography
                    variant="h1"
                    sx={{
                      position: 'absolute',
                      top: { xs: '-20px', md: '40px' },
                      left:
                        index % 2 === 0 ? { xs: '50%', md: '-20px' } : { xs: 'unset', md: 'unset' },
                      right:
                        index % 2 !== 0 ? { xs: '50%', md: '-20px' } : { xs: 'unset', md: 'unset' },
                      transform:
                        index % 2 === 0
                          ? { xs: 'translateX(-50%)', md: 'none' }
                          : { xs: 'translateX(50%)', md: 'none' },
                      color: theme.palette.grey[500],
                      opacity: 0.3,
                      fontSize: { xs: '100px', md: '200px' },
                      zIndex: 0,
                      userSelect: 'none',
                      fontWeight: 1000,
                    }}
                  >
                    0{item.id}
                  </MKTypography>
                  {/* Contenido de texto */}
                  <MKTypography variant="h3" fontWeight="bold" gutterBottom sx={{ zIndex: 1 }}>
                    {item.title}
                  </MKTypography>
                  <MKTypography
                    color="textSecondary"
                    sx={{
                      fontSize: '20px',
                      fontWeight: 'light',
                      zIndex: 1,
                    }}
                  >
                    {item.subtitle}
                  </MKTypography>
                </MKBox>
              </Grid>

              {/* Componente de Imagen */}
              <Grid
                item
                xs={12}
                md={6}
                order={{
                  xs: 2,
                  md: index % 2 === 0 ? 2 : 1,
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <MKBox
                  component="img"
                  src={item.image}
                  alt={item.title}
                  sx={{
                    width: '100%',
                    maxWidth: '300px',
                    height: 'auto',
                    transform: inView ? 'scale(1)' : 'scale(0.8)',
                    transition: 'transform 0.6s ease-out',
                  }}
                />
              </Grid>
            </Grid>
          );
        })}
      </CustomContainer>
    </MKBox>
  );
};

export default InfoBanner;
