// src/helpers/whatsappHelper.js

export const generateWhatsAppURL = (phoneNumber, message) => {
  // Elimina cualquier carácter que no sea dígito
  const sanitizedNumber = phoneNumber.replace(/\D/g, '');

  // Codifica el mensaje
  const encodedMessage = encodeURIComponent(message);

  return `https://wa.me/${sanitizedNumber}?text=${encodedMessage}`;
};
