import { useEffect } from 'react';

// Section
import BannerAcceleration from '../../academyLanding/sections/Acceleration/BannerAcceleration';
import Testimonials from '../../academyLanding/sections/Acceleration/Testimonials';
import CardDescription from '../../academyLanding/sections/Acceleration/CardsDescription';
import AlumniAcceleration from 'pages/academyLanding/sections/Acceleration/AlumniAcceleration';
import Header from 'pages/shared/components/Header';
import DetailedFooter from 'DS/complex/Footers/DetailedFooter';
import footerRoutes from 'footer.routes';
import CtaAcademy from 'pages/academyLanding/sections/CallToActionAcademy';
import ContactUs from 'pages/home/sections/ContactUs';
import FAQ from 'pages/academyLanding/sections/FAQ';

function AcademyLandingAcceleration() {
  return (
    <>
      <Header transparent light relative />
      <BannerAcceleration />
      <CardDescription />
      <AlumniAcceleration />
      <Testimonials />
      <FAQ />
      <CtaAcademy />
      <ContactUs />
      <DetailedFooter content={footerRoutes} />
    </>
  );
}

export default AcademyLandingAcceleration;
