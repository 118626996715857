import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, LabelList } from 'recharts';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material';
import CustomContainer from 'DS/components/Container';
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';

import img from 'assets/images/graph-build.png';

const SimpleBarChart = () => {
  const theme = useTheme();

  return (
    <MKBox
      sx={{
        width: { xs: '92%', sm: '92%' },
        display: 'grid',
        placeItems: 'center',
        p: { xs: 4, sm: 10 },
        mx: { xs: 'auto', sm: 'auto' },
        my: 10,
        borderRadius: '60px',
      }}
    >
      <CustomContainer sx={{ maxWidth: '1620px!important' }} maxWidth={false}>
        {/* Título */}
        <MKBox py={10} textAlign={'flex-start'}>
          <Grid container spacing={1} justifyContent="flex-start">
            <Grid item xs={12} lg={7} alignItems="flex-start" flexDirection="column">
              <MKTypography
                variant="h4"
                align="flex-start"
                color="text"
                sx={{
                  fontWeight: 400,
                  letterSpacing: 1,
                  marginBottom: 4,
                }}
              >
                <b>What </b>were we working on
              </MKTypography>
            </Grid>
          </Grid>
        </MKBox>
        <MKBox
          component="img"
          src={img}
          alt={'Proyectos'}
          width="100%"
          position="relative"
          zIndex={1}
        />
      </CustomContainer>
    </MKBox>
  );
};

export default SimpleBarChart;
