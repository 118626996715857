// react-router-dom components
import { Link } from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui material components
import Grid from '@mui/material/Grid';
import CustomContainer from 'DS/components/Container';
import MKButton from 'DS/components/MKButton';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';
// Images

function VenturesFooter({ content }) {
  const { brand, socials, menus, submenus, copyright, contact, action } = content;

  const Background = {
    background: { xs: 'inherit', md: '#5A5EA6' },
    position: 'relative',
    zIndex: '0',
    '&::before': {
      content: '""',
      display: 'flex',
      position: 'absolute',
      height: '100%',
      background: 'inherit',
      width: { xs: '200%', md: 'calc(100% + 48px)' },
      zIndex: '-2',
      top: { xs: '0', md: '-48px' },
      left: { xs: '-50%', md: '0' },
    },
    '&::after': {
      content: '""',
      display: 'flex',
      position: 'absolute',
      height: '100%',
      background: 'inherit',
      width: { xs: '200%', md: 'calc(100% + 48px)' },
      zIndex: '-2',
      bottom: '-48px',
      left: { xs: '-50%', md: '0' },
    },
  };

  return (
    <MKBox component="footer" display="flex" position="relative">
      <CustomContainer>
        <Grid
          container
          spacing={3}
          borderRadius={6}
          justifyContent="space-between"
          backgroundColor="#EFF0FF"
          overflow={'hidden'}
          mt={6}
          gap={'16px'}
          sx={{
            width: { xs: '100%' },
            marginLeft: { xs: '0' },
            px: { xs: 1, md: 3 },
            py: { xs: 3, md: 6 },
          }}
        >
          <Grid item xs={12} lg={3}>
            <Link to={brand.route}>
              <MKBox component="img" src={brand.image} alt={brand.name} maxWidth="2rem" />
            </Link>
            <MKTypography variant="body2" color="text" my={3} pb={1} pr={3}>
              ¿ Like what you see ? Lets talk
            </MKTypography>
            <MKBox display="flex" alignItems="center">
              {socials.map(({ icon, link }, key) => (
                <MKTypography
                  key={link}
                  component="a"
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  variant="body1"
                  color="dark"
                  mr={key === socials.length - 1 ? 0 : 3}
                  sx={{ fontSize: '28px!important' }}
                >
                  {icon}
                </MKTypography>
              ))}
            </MKBox>
          </Grid>
          {contact && (
            <Grid item xs={12} md={2}>
              <MKTypography
                component="h5"
                variant="h5"
                fontWeight="bold"
                textTransform="capitalize"
                mb={3}
              >
                Information
              </MKTypography>
              <MKBox component="ul" p={0} m={0} sx={{ listStyle: 'none' }}>
                {contact.map(({ icon, link, text }, key) => (
                  <MKBox
                    key={text}
                    component="li"
                    p={0}
                    m={0}
                    lineHeight={1.25}
                    pb={1}
                    sx={{
                      height: '32px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignContent: 'center',
                      alignItems: 'center',
                      gap: '6px',
                    }}
                  >
                    <MKTypography
                      component="a"
                      href={link}
                      target="_blank"
                      rel="noreferrer"
                      color="dark"
                      fontWeight="regular"
                      textTransform="capitalize"
                      variant="footerList"
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignContent: 'center',
                        alignItems: 'center',
                        gap: '6px',
                      }}
                    >
                      {icon}
                      {text}
                    </MKTypography>
                  </MKBox>
                ))}
              </MKBox>
            </Grid>
          )}

          {action && (
            <Grid
              item
              xs={12}
              lg={2}
              sx={{
                ...Background,
              }}
            >
              <MKBox display="flex" flexDirection="column" paddingRight="24px">
                <MKTypography
                  variant="h5"
                  mb={3}
                  pb={1}
                  pr={3}
                  color="white"
                  sx={{ display: { xs: 'none', md: 'flex' } }}
                >
                  ¿ Want to learn more ?
                </MKTypography>
                <MKTypography
                  variant="h5"
                  mb={3}
                  pb={1}
                  pr={3}
                  color="dark"
                  sx={{ display: { xs: 'flex', md: 'none' } }}
                >
                  ¿ Want to learn more ?
                </MKTypography>
                <MKButton
                  onClick={() => {
                    window.location = 'https://wa.me/+5491167161749?text=Hola Koi';
                  }}
                  mx={4}
                  color={'secondary'}
                  size="large"
                  sx={{ display: { xs: 'none', md: 'flex' } }}
                >
                  {action.textLink}
                </MKButton>
                <MKButton
                  onClick={() => {
                    window.location = 'https://wa.me/+5491167161749?text=Hola Koi';
                  }}
                  mx={4}
                  color={'dark'}
                  size="large"
                  width="100%"
                  sx={{ display: { xs: 'flex', md: 'none' } }}
                >
                  {action.textLink}
                </MKButton>
              </MKBox>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={3} py={6}>
          <Grid item xs={12} sx={{ mb: 3 }}>
            <>
              <MKBox
                display="flex"
                justifyContent="space-between"
                gap="24px"
                sx={{
                  flexDirection: { xs: 'column', md: 'row' },
                }}
              >
                <MKBox display="flex" gap="36px">
                  {submenus.map(({ href, name }, key) => (
                    <MKTypography
                      component="a"
                      key={key}
                      href={href}
                      target="_blank"
                      rel="noreferrer"
                      color="primary"
                      fontWeight="bold"
                      textTransform="capitalize"
                      variant="footerList"
                      sx={{
                        borderBottom: '1px solid #5D5DFB',
                      }}
                    >
                      {name}
                    </MKTypography>
                  ))}
                </MKBox>
                <MKTypography
                  component="span"
                  color="dark"
                  fontWeight="regular"
                  textTransform="capitalize"
                  variant="footerList"
                >
                  {copyright}
                </MKTypography>
              </MKBox>
            </>
          </Grid>
        </Grid>
      </CustomContainer>
    </MKBox>
  );
}

// Typechecking props for the DetailedFooter
VenturesFooter.propTypes = {
  content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
};

export default VenturesFooter;
