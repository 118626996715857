/* eslint-disable no-unused-vars */

import { useEffect, useState } from 'react';

// react-router components
import { useLocation } from 'react-router-dom';

// @mui material components
import { ThemeProvider } from '@mui/material/styles';

// CFOStartup React themes
import theme from 'assets/theme';

// CFOStartup React contexts
import { AuthProvider } from 'contexts/auth/auth.provider';
import { OnboardingControllerProvider } from 'contexts/onboarding';
import { useUIController } from 'contexts/ui';

import AppWrapper from './AppWrapper';
import Loader from 'pages/shared/loader';

export default function App() {
  const [controller, dispatch] = useUIController();
  const { direction } = controller;

  const { pathname } = useLocation();

  // Estado para controlar el loader
  const [loading, setLoading] = useState(true);

  // Función que se ejecuta cuando el loader termina
  const handleLoaderFinish = () => {
    setLoading(false);
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return (
    <>
      {loading && <Loader onFinish={handleLoaderFinish} />}
      {!loading && (
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <OnboardingControllerProvider>
              <AppWrapper />
            </OnboardingControllerProvider>
          </AuthProvider>
        </ThemeProvider>
      )}
    </>
  );
}
