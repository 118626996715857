// src/components/withScrollAnimation.js

import React, { useRef, useState, useEffect } from 'react';

const withScrollAnimation = (WrappedComponent) => {
  const ScrollAnimation = (props) => {
    const [isVisible, setVisible] = useState(false);
    const domRef = useRef();

    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => setVisible(entry.isIntersecting));
        },
        {
          threshold: 0.3,
        }
      );
      const { current } = domRef;
      if (current) {
        observer.observe(current);
      }
      return () => {
        if (current) {
          observer.unobserve(current);
        }
      };
    }, []);

    return (
      <div
        ref={domRef}
        style={{
          opacity: isVisible ? 1 : 0,
          transform: isVisible ? 'none' : 'translateY(20px)',
          transition: 'opacity 1s ease-out, transform 1s ease-out',
        }}
      >
        <WrappedComponent {...props} />
      </div>
    );
  };

  return ScrollAnimation;
};

export default withScrollAnimation;
