import { useEffect } from 'react';

// Routes
import footerRoutes from 'footer.routes';

// Section
import DetailedFooter from 'DS/complex/Footers/DetailedFooter';
import Team from './sections/Team';
import Header from 'pages/shared/components/Header';
import ContactUs from './sections/ContactUs';
import TeamCeo from './sections/TeamCeo';

function AboutUsLanding() {
  useEffect(() => {
    document.title = 'Koi Ventures';
  }, []);

  return (
    <>
      <Header transparent light relative />
      <TeamCeo />
      <Team />
      <ContactUs />
      <DetailedFooter content={footerRoutes} />
    </>
  );
}

export default AboutUsLanding;
