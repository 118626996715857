// @mui material components
import Grid from '@mui/material/Grid';

import CustomContainer from 'DS/components/Container';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKButton from 'DS/components/MKButton';
import MKTypography from 'DS/components/MKTypography';

// Images
import bgImage from 'assets/images/cta.png';
import { analyticsTag } from 'helpers/coreHelper';

import fishDown from 'assets/images/fish_down.png';
import fishUp from 'assets/images/fish_up.png';
import logoBlack from 'assets/images/logos/Koi_black.png';
import rocket from 'assets/images/logos/Rocket.png';

function CtaAcademy() {
  const Background = {
    backgroundImage: { xs: 'none', sm: 'none', md: `url(${bgImage})` },
    backgroundSize: '20% 100%',
    backgroundColor: '#FCAE9B',
    backgroundPosition: 'left top',
    backgroundRepeat: 'no-repeat',
  };

  const buttonStyle = {
    position: 'relative',
    '&:after': {
      content: '""',
      backgroundImage: `url(${fishDown})`,
      backgroundRepeat: 'no-repeat',
      display: { xs: 'none', md: 'block' },
      position: 'absolute',
      bottom: '85%',
      right: '80%',
      width: '54px',
      height: '136px',
      zIndex: '1',
      transform: 'rotate(60deg)',
    },
    '&:before': {
      content: '""',
      backgroundImage: `url(${fishUp})`,
      backgroundRepeat: 'no-repeat',
      display: { xs: 'none', md: 'block' },
      position: 'absolute',
      top: '85%',
      left: '80%',
      width: '54px',
      height: '136px',
      zIndex: '-1',
      transform: 'rotate(60deg)',
    },
  };

  return (
    <MKBox
      display="flex"
      py={4}
      my={20}
      position="relative"
      sx={{
        ...Background,
      }}
    >
      <CustomContainer>
        <Grid
          container
          alignItems="center"
          alignContent="center"
          // textAlign="center"
          sx={{
            gap: { xs: '12px', sm: '24px', md: '64px' },
            paddingLeft: { sm: 0, md: '25%' },
            justifyContent: { xs: 'center', md: 'start' },
          }}
        >
          <img src={logoBlack} alt="Koi Ventures" style={{ width: '50px' }} />
          <MKTypography
            variant="h4"
            color="dark"
            fontWeight="bold"
            display="inline-flex"
            gap="16px"
            alignItems="center"
          >
            Are you ready to scale up your startup?
          </MKTypography>
          <MKButton
            color="black"
            size="large"
            sx={buttonStyle}
            onClick={() => {
              analyticsTag({
                event: 'application_academy',
              });
              window.open(
                'https://airtable.com/appboYA2hPja4NXFz/pagXXm6eGX0rtIH7o/form',
                '_blank'
              );
            }}
          >
            Apply Now
          </MKButton>
        </Grid>
      </CustomContainer>
    </MKBox>
  );
}

export default CtaAcademy;
