/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://koiventures.tech/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://koiventures.tech)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui material components
import Grid from '@mui/material/Grid';
import CustomContainer from 'DS/components/Container';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';
// Images
import fondoFooter from 'assets/images/banners/fondoFooter.png';
import circleArrowRight from 'assets/images/icons/circle-arrow-right.svg';
import koiLogoWhite from 'assets/images/logos/koiLogoWhite.svg';

function DetailedFooter({ content }) {
  const { brand, socials, menus, submenus, copyright, contact, action } = content;

  return (
    <MKBox
      display="flex"
      sx={{
        backgroundImage: `url(${fondoFooter})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: 'auto',
        flexDirection: 'column',
        pt: { xs: 6, md: 0 },
      }}
    >
      <CustomContainer>
        <Grid
          container
          spacing={3}
          borderRadius={6}
          justifyContent="space-around"
          overflow={'hidden'}
          mt={6}
          gap={'16px'}
          sx={{
            width: { xs: '100%' },
            marginLeft: { xs: '0' },
            px: { xs: 1, md: 3 },
            py: { xs: 10, md: 6 },
          }}
        >
          <Grid item xs={12} lg={3}>
            <Link to={brand.route}>
              <MKBox component="img" src={koiLogoWhite} alt={brand.name} width="120px" />
            </Link>
            <MKTypography variant="body2" color="white" my={3} pb={1} pr={3}>
              {brand.description}
            </MKTypography>
            <MKBox display="flex" gap="36px">
              {submenus.map(({ href, name }, key) => (
                <MKTypography
                  component="a"
                  key={key}
                  href={href}
                  rel="noreferrer"
                  color="white"
                  fontWeight="bold"
                  textTransform="capitalize"
                  variant="footerList"
                  sx={{
                    borderBottom: '1px solid #fff',
                  }}
                >
                  {name}
                </MKTypography>
              ))}
            </MKBox>
          </Grid>
          {contact && (
            <Grid item xs={12} md={2}>
              <MKTypography
                component="h5"
                variant="h5"
                fontWeight="bold"
                textTransform="capitalize"
                mb={3}
                color="white"
              >
                Information
              </MKTypography>
              <MKBox component="ul" p={0} m={0} sx={{ listStyle: 'none' }}>
                {contact.map(({ icon, link, text }, key) => (
                  <MKBox
                    key={text}
                    component="li"
                    p={0}
                    m={0}
                    lineHeight={1.25}
                    pb={1}
                    sx={{
                      height: '32px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignContent: 'center',
                      alignItems: 'center',
                      gap: '6px',
                    }}
                  >
                    <MKTypography
                      component="a"
                      href={link}
                      target="_blank"
                      rel="noreferrer"
                      color="white"
                      fontWeight="regular"
                      textTransform="capitalize"
                      variant="footerList"
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignContent: 'center',
                        alignItems: 'center',
                        gap: '6px',
                      }}
                    >
                      {icon}
                      {text}
                    </MKTypography>
                  </MKBox>
                ))}
              </MKBox>
            </Grid>
          )}
          {menus.map(({ name: title, items }, key) => (
            <Grid key={title} item xs={12} md={2}>
              <MKTypography
                component="h5"
                variant="h5"
                fontWeight="bold"
                textTransform="capitalize"
                mb={3}
                color="white"
              >
                Services
              </MKTypography>
              <MKBox component="ul" p={0} m={0} sx={{ listStyle: 'none' }}>
                {items.map(({ name, route, href }) => (
                  <MKBox
                    key={name}
                    component="li"
                    p={0}
                    m={0}
                    lineHeight={1.25}
                    pb={1}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignContent: 'center',
                      alignItems: 'center',
                      gap: '6px',
                    }}
                  >
                    <MKBox component="img" src={circleArrowRight} maxWidth="2rem" color="white" />
                    {href ? (
                      <MKTypography
                        component="a"
                        href={href}
                        rel="noreferrer"
                        color="white"
                        fontWeight="regular"
                        textTransform="capitalize"
                        variant="footerList"
                      >
                        {name}
                      </MKTypography>
                    ) : (
                      <MKTypography
                        component={Link}
                        to={route}
                        variant="button"
                        color="secondary"
                        fontWeight="regular"
                        textTransform="capitalize"
                        pb={0.5}
                      >
                        {name}
                      </MKTypography>
                    )}
                  </MKBox>
                ))}
              </MKBox>
            </Grid>
          ))}
          {contact && (
            <Grid item xs={12} md={2}>
              <MKTypography
                component="h5"
                variant="h5"
                fontWeight="bold"
                textTransform="capitalize"
                mb={3}
                color="white"
              >
                Follow us
              </MKTypography>
              <MKBox component="ul" p={0} m={0} sx={{ listStyle: 'none' }}>
                {socials.map(({ icon, link, text }, key) => (
                  <MKBox
                    key={text}
                    component="li"
                    p={0}
                    m={0}
                    lineHeight={1.25}
                    pb={1}
                    sx={{
                      height: '32px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignContent: 'center',
                      alignItems: 'center',
                      gap: '6px',
                    }}
                  >
                    <MKTypography
                      component="a"
                      href={link}
                      target="_blank"
                      rel="noreferrer"
                      color="white"
                      fontWeight="regular"
                      textTransform="capitalize"
                      variant="footerList"
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignContent: 'center',
                        alignItems: 'center',
                        gap: '6px',
                      }}
                    >
                      {icon}
                      {text}
                    </MKTypography>
                  </MKBox>
                ))}
              </MKBox>
            </Grid>
          )}
        </Grid>
      </CustomContainer>
    </MKBox>
  );
}

// Typechecking props for the DetailedFooter
DetailedFooter.propTypes = {
  content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
};

export default DetailedFooter;
