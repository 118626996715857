// src/components/Banner.js

import React from 'react';
import { useEffect, useState } from 'react';

// Componentes de Material-UI
import { useTheme } from '@emotion/react';

// Componentes personalizados de Material Kit 2 PRO React
import MKTypography from 'DS/components/MKTypography';

// Funciones auxiliares

// Imágenes (si son necesarias en el futuro)
import { Box, Grid } from '@mui/material';
import HoverCard from '../components/HoverCard';
import bannerSMEs from 'assets/images/banners/buildBanner/bannerSMEs.jpeg';
import bannerInternslProducts from 'assets/images/banners/buildBanner/bannerInternalProducts.jpeg';
import bannerBusinessUnits from 'assets/images/banners/buildBanner/bannerBusinessUnits.jpeg';

// Hook personalizado para manejar la coincidencia de consultas de medios
function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    const listener = () => setMatches(media.matches);
    media.addEventListener('change', listener);
    // Establecer el estado inicial
    setMatches(media.matches);
    return () => media.removeEventListener('change', listener);
  }, [query]);

  return matches;
}

function Program() {
  const theme = useTheme();
  const isXs = useMediaQuery('(max-width: 720px)');

  return (
    <Box
      sx={{
        m: { xs: 2, sm: 3, md: 5 },
      }}
    >
      {/* Título */}
      <MKTypography
        variant="h4"
        align="start"
        color="text"
        sx={{
          fontWeight: 400,
          letterSpacing: 1,
          marginBottom: 4,
        }}
      >
        <b>What</b> we do
      </MKTypography>

      {/* Contenedor de las Tarjetas */}
      <Grid
        container
        spacing={4}
        justifyContent="center"
        alignItems="center"
        sx={{
          flexDirection: { xs: 'column', sm: 'row' },
        }}
      >
        {/* Tarjeta 1 */}
        <Grid item>
          <HoverCard
            title="Startups & SMEs"
            description="Collaborating with SMEs and Startups that present a problem and solution concept, we support them through the process of refining, validating, and scaling their ideas."
            // cta="EXPLORE"
            image={bannerSMEs}
          />
        </Grid>

        {/* Tarjeta 2 */}
        <Grid item>
          <HoverCard
            title="Internal Products"
            description="Exploring impactful problems and building solutions—sometimes independently, other times hand-in-hand with visionary entrepreneurs."
            // cta="EXPLORE"
            image={bannerInternslProducts}
          />
        </Grid>

        {/* Tarjeta 3 */}
        <Grid item>
          <HoverCard
            title="Business Units"
            description="When a projects result in a strategic and we think our team is the best to do it we transform it in a business model."
            // cta="EXPLORE"
            image={bannerBusinessUnits}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Program;
