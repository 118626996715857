/* eslint-disable no-console */
import { useEffect } from 'react';

// Routes
import footerRoutes from 'footer.routes';

// Section
import Container from '@mui/material/Container';
import VenturesFooter from 'DS/complex/Footers/VenturesFooter';

import HeaderVentures from './components/HeaderVentures';

// HelpCenter page components

import Grid from '@mui/material/Grid';
import MKTypography from 'DS/components/MKTypography';

function ContactSuccess() {
  useEffect(() => {
    document.title = 'Koi Ventures - Success';
  }, []);

  return (
    <>
      <HeaderVentures transparent light relative />

      <Container>
        <Grid spacing={3} sx={{ mt: 3, ml: { lg: 20 }, mr: { lg: 20 } }}>
          <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'center' }}>
            <MKTypography variant="titleForm" color="primary">
              We received your request, we will be contacting you.
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
      <VenturesFooter content={footerRoutes} />
    </>
  );
}

export default ContactSuccess;
