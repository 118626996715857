// Routes
import footerRoutes from 'footer.routes';

// Section
import AboutUs from './sections/AboutUs';
import Banner from './sections/Banner';
import Companies from './sections/Companies';
import LetsWork from './sections/LetsWork';
import Vision from './sections/Vision';

import { Box } from '@mui/material';
import fondoVision from 'assets/images/FondoVision.png';
import DetailedFooter from 'DS/complex/Footers/DetailedFooter';
import Header from 'pages/shared/components/Header';
import { useEffect } from 'react';
import Team from './sections/Team';

function Landing() {
  useEffect(() => {
    document.title = 'Home';
  }, []);
  return (
    <Box
      sx={{
        backgroundImage: `url(${fondoVision})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '100%',
      }}
    >
      <Header transparent light sticky />
      <Banner />
      <Vision />
      <AboutUs />
      <Companies />
      <Team />
      <LetsWork />
      <DetailedFooter content={footerRoutes} />
    </Box>
  );
}

Landing.getMeta = () => {
  return (
    <>
      <title>{`Home`}</title>
    </>
  );
};

export default Landing;
